import React, { useEffect, useState } from 'react'
import Header from '../../../Navbar/Header'
import Sidebar from '../../sidebar/Sidebar'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { tradeLabel } from '../../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { trade_create, trade_delete, trade_get } from '../../../../middleware/AttendancetradeMiddleware';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
export default function Trade() {
    const dispatch = useDispatch()

    const tradedata = useSelector(state => state.Trade)
    const [tradeModifideData, setTradeModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
    });
    const [trade, setTrade] = useState();

    const validationSchema = Yup.object().shape({
        trade: Yup.string().required(tradeLabel.trade_required),
    })

    const handlesubmit = async (values, formik) => {
        let response = await dispatch(trade_create(values));
        const isSuccess = response?.payload?.status === 200;
        const messageType = isSuccess ? "success" : "error";

        toast(response?.payload?.data?.message, {
            type: messageType,
            autoClose: 2000
        });

        if (isSuccess) {
            const fieldsToReset = ['trade'];

            fieldsToReset.forEach(field => {
                formik.setFieldValue(field, '');
                formik.setFieldTouched(field, false);
            });
            dispatch(trade_get())

        }
    };
    useEffect(() => {
        dispatch(trade_get())
    }, [])

    useEffect(() => {
        console.log(tradedata)
        if (tradedata?.trade?.status == 200) {
            setTrade(tradedata?.trade?.data);
        }
    }, [tradedata])

    const tradedata_delete = async (tradeId) => {
        const response = await dispatch(trade_delete(tradeId));
        console.log(response)
        if (response?.payload?.status === 200) {
            setTrade((prevTrade) =>
            prevTrade.filter((trade) => trade.id !== tradeId)
            );

            toast(response?.payload?.data?.message, {
                type: "success",
                autoClose: 2000
            });
        } else {
            toast(response?.payload?.response?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }
    };
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ trade: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handlesubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className='row px-4 mt-2'>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Trade Name</label>
                                                <Field type='text' className='form-control' placeholder='Enter trade Name' name='trade' />
                                                <ErrorMessage name='trade' component='div' className="text-danger" />
                                            </div>
                                        </div>


                                        <div className=' m-4'>
                                            <button className="btn defult-button" type="submit">Submit</button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className='card custom-card mt-3'>
                            <div className='table-responsive border border-1'>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='text-center'>S.No</th>
                                            <th className='text-center'>Trade Name</th>
                                            <th className='text-center'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trade && trade.length > 0 ? (
                                            trade.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td className='text-center'> {data.trade}</td>
                                                    <td>
                                                        <Button className='table-custom-button my-1' variant="primary" onClick={() => setTradeModifideData((prs) => {
                                                            return {
                                                                ...prs,
                                                                deleteIdData: data,
                                                                deleteModel: true
                                                            };
                                                        })
                                                        }
                                                        ><i className='fa fa-trash px-3'></i></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className='text-center'>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={tradeModifideData.deleteModel} onClick={() => setTradeModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this staff <b>{tradeModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTradeModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button"
                        onClick={() => tradedata_delete(tradeModifideData?.deleteIdData?.id)}

                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
