import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { runPayroll } from '../hrmsSlice';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
export default function RunPayrollModal({ closePayRoll }) {
  const dispatch = useDispatch()
  const [commonLoad, setCommonLoad] = useState(false)
  const companyData = useSelector(state => state.hrms.companyData?.companies || []);

  const fileInputRef = useRef(null);
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    closePayRoll();
  };
  const validationSchema = Yup.object().shape({
    csvFile: Yup.mixed().required('CSV file is required'),
  });
  const handleSubmit = async (values, { resetForm }) => {
    setCommonLoad(true)
    const formData = new FormData();
    formData.append('file', values.csvFile);
    const response = await dispatch(runPayroll({ formData, company_id: values.company_id }))
    console.log(response)
    if (response.payload.status === 201) {
      setCommonLoad(false)
      toast.success(response.payload.data.message)
      handleClose()
      window.location.reload();
    }else{
      toast.error(response.payload.data.message)
      handleClose()
    }
    // resetForm()
  }

  return (
    <>
     
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Run you payroll Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {commonLoad && (
        <div className="spinner-container"> {/* Wrap the spinner with a div */}
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>)}
          <Formik
            initialValues={{ csvFile: null }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}

          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
              resetForm
            }) => (
              <Form>
                <div className='col-md-12  mt-2'>
                  <label className='form-label'>Upload the Excel </label>
                  <input ref={fileInputRef}
                    type='file'
                    accept='.xlsx, .xls'
                    className='form-control'
                    placeholder='Enter your name'
                    name='csvFile'
                    onChange={(event) => {
                      setFieldValue('csvFile', event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage name='csvFile' component='div' className='text-danger' />


                </div>
                {/* <div className="col-md-6 px-3 py-2">
                <label className='form-label'>Company</label>
                <Field as="select" className='form-select' name="company_id" >
                <option value="" label="Select a company" />
                  {companyData.map(company => (
                    
                    <option key={company.company_id} value={company.company_id}>
                      {company.company_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name='company_id' component='div' className="text-danger" />

              </div> */}

                <div className='mt-3'>
                  <button className='cancel-button ms-2' onClick={handleClose}>Cancel</button>

                  <button className='btn defult-button ms-2' type='submit' disabled={!values.csvFile}>
                    Submit
                  </button>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>

      </Modal>
    </>
  )
}