import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet, apiPost } from "../service/apiService";
import { endPoint } from "../config/endPoint";



export const announcement_group_create = createAsyncThunk("announcement_group_create",async(payload)=> {
    const response  = await apiPost(endPoint.announcement_group , payload, "");
    return response
})


export const announcement_group_get = createAsyncThunk("announcement_group_get",async(payload) => {
    const response = await apiGet(endPoint.announcement_group,payload,'');
    return response
})



export const announcement_group_delete = createAsyncThunk("announcement_group_delete", async (payload) => {
    const response = await apiDelete(endPoint.announcement_group + '/' + payload)
    return response
})

