import React, { useEffect, useState } from 'react'
import Header from '../../../Navbar/Header'
import Sidebar from '../../sidebar/Sidebar'

import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { announcementLabel } from '../../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { announcement_approval_msg, announcement_get_approval_msg, announcement_msg_delete } from '../../../../middleware/AnnouncementmsgMiddleware';
import { toast } from 'react-toastify';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export default function Approval() {
    const [messageModifideData, setMessageModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
    });
    const dispatch = useDispatch()
    const
        validationSchema = Yup.object().shape({
            approvalMsg: Yup.string().required(announcementLabel.approve_required),
        })

    const [msgapproval, setMsgapproval] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sendmsgloading, setSendmsgoading] = useState(false);


    const groupData = useSelector((state) => state.Announcementmessage);



    useEffect(() => {
        setLoading(true);
        dispatch(announcement_get_approval_msg()).then(() => {
            setLoading(false);
        });
    }, [dispatch]);



    useEffect(() => {
        if (groupData?.approval?.status == 200) {
            setMsgapproval(groupData?.approval?.data || []);
        }
    }, [groupData])


    const deleteStaffData = async (message) => {
       
        const payload = new URLSearchParams({ message }).toString();

        const response = await dispatch(announcement_msg_delete(payload));
        if (response?.payload?.status === 200) {
          dispatch(announcement_get_approval_msg())

          toast(response?.payload?.data?.message, {
            type: "success",
            autoClose: 2000
          });

        } else {
          toast(response?.payload?.response?.data?.message, {
            type: "error",
            autoClose: 2000
          });
        }
    };


    const handleSubmit = async (values, { formik, setFieldValue, resetForm }) => {
        setLoading(true);
        setSendmsgoading(true)
        console.log('start')
        const formData = new FormData();
        formData.append('approvalMsg', values.approvalMsg);
        formData.append('media', values.media)
        try {
            const response = await dispatch(announcement_approval_msg(formData));

            if (response?.payload?.status === 200) {
                setSendmsgoading(false)
                await dispatch(announcement_get_approval_msg());

                toast(response?.payload?.data?.message, {
                    type: "success",
                    autoClose: 2000
                });
                // formik.setFieldValue('approvalMsg', '');
                resetForm()
                setFieldValue('media', null)
                const fileInput = document.querySelector('input[type="file"]');
                if (fileInput) {
                    fileInput.value = ''; // This clears the selected file
                }
            } else if (response?.payload?.status !== 200) {
                toast(response?.payload?.data?.error, {
                    type: "error",
                    autoClose: 2000
                });
            }
        } catch (error) {
            console.error("API request failed:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ approvalMsg: '' }}
                                validationSchema={validationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                    handleChange,
                                    resetForm,
                                    setFieldValue,
                                }) => (
                                    <Form>
                                        <div className='row px-4 mt-2'>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Message</label>
                                                <Field as="textarea" type='text' className='form-control' placeholder='Enter Message' name='approvalMsg' style={{height:'400px'}}/>
                                                <ErrorMessage name='approvalMsg' component='div' className="text-danger" />
                                            </div>

                                            <div className='col-md-6'>
                                                <label className='form-label'>Attahcment Image</label>
                                                <input type="file" className='form-control' onChange={(event) => {
                                                    setFieldValue('media', event.currentTarget.files[0]);
                                                }} />

                                                {values.media && (
                                                    <div className='mt-2 profileimage'>
                                                        <img
                                                            src={URL.createObjectURL(values.media)}
                                                            alt="Selected media"
                                                        />
                                                    </div>
                                                )}



                                            </div>
                                        </div>
                                        <div className=' m-4'>

                                            <button className="btn defult-button" type="submit" disabled={sendmsgloading}>Submit</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='card custom-card mt-3'>
                            <div className='table-responsive border border-1'>
                                {msgapproval.length > 0 ? (
                                    <table className='table table-striped-custom table-hover'>
                                        <thead className='table-head'>
                                            <tr>
                                                <th >S.No</th>
                                                <th >Message</th>
                                                <th >Image Url</th>
                                                <th >Status</th>
                                                <th >Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {msgapproval.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>  {item?.types["twilio/text"]?.body || item?.types["twilio/media"]?.body}</td>
                                                    <td><p className='approval-img-text'>{item?.types["twilio/media"]?.media[0]}</p></td>

                                                       
                                                        <td className='align-middle'><p className={`badge ${item?.approval_requests?.status === 'approved' ? 'bg-success' : 'bg-danger'} m-0 p-2`}> {item?.approval_requests?.status}</p></td>
                                                    <td  className='align-middle'>
                                                        

                                                            <Button className='table-custom-button  my-1' variant="primary" onClick={() => setMessageModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: item,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>
                                                       
                                                    </td>


                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    loading ? (
                                        <div className='text-center'>Loading data...</div>
                                    ) : (
                                        <div className='text-center'>No data available.</div>
                                    )
                                )}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Modal show={messageModifideData.deleteModel} onClick={() => setMessageModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                {messageModifideData?.deleteIdData?.types["twilio/text"]?.body ?
                    <Modal.Body>Are you sure, you want to delete this  <b>{messageModifideData?.deleteIdData?.types["twilio/text"]?.body}</b>?</Modal.Body> :

                    <Modal.Body>Are you sure, you want to delete this  <b>{messageModifideData?.deleteIdData?.types["twilio/media"]?.body}</b>?</Modal.Body>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setMessageModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    {messageModifideData?.deleteIdData?.types["twilio/text"]?.body ?
                        <Button className="model-save-button"
                            onClick={() => deleteStaffData(messageModifideData?.deleteIdData?.types["twilio/text"]?.body)}>
                            Delete
                        </Button> :
                        <Button className="model-save-button"
                            onClick={() => deleteStaffData(messageModifideData?.deleteIdData?.types["twilio/media"]?.body)}>
                            Delete
                        </Button>}

                </Modal.Footer>
            </Modal>
        </div>
    )
}