import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { useDispatch } from 'react-redux';
import { announcement_msg_check, announcement_msg_send } from '../../../../middleware/AnnouncementmsgMiddleware';
import Sidebar from '../../sidebar/Sidebar';
import Header from '../../../Navbar/Header';
import { useEffect } from 'react';
import { announcement_group_get } from '../../../../middleware/AnnouncementMiddleware';
import { stateToHTML } from 'draft-js-export-html';
import { toast } from 'react-toastify';

export default function Message() {
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [groupdetails, setGroupdetails] = useState('')
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };
    const [selectedFilter, setSelectedFilter] = useState('');
    useEffect(() => {
        dispatch(announcement_group_get()).then(response => {
            if (response?.payload?.status === 200) {
                setGroupdetails(response?.payload?.data?.announcement_groups || []);
            }
        });
    }, []);

    const handleSave = async () => {
        try {

            const contentState = editorState.getCurrentContent();
            const contentAsHTML = stateToHTML(contentState);

            const payload = {
                message: contentAsHTML,
            };
            let response = await dispatch(announcement_msg_check(payload));
            console.log(response)
            if (response?.payload?.status === 200) {
                const formData = new FormData();
                formData.append('message', contentAsHTML);
                formData.append('groupName', selectedFilter);
                formData.append('templateID', response?.payload?.data?.sid)

                let message_response = await dispatch(announcement_msg_send(formData));
                if (message_response?.payload?.status === 200) {

                    toast(message_response?.payload?.data?.message, {
                        type: "success",
                        autoClose: 2000
                    });
                } else {
                    toast(message_response?.payload?.data?.message, {
                        type: "error",
                        autoClose: 2000
                    });
                }
                setEditorState(EditorState.createEmpty());
                setSelectedFilter('')

            } else {
                toast(response?.payload?.data?.message, {
                    type: "error",
                    autoClose: 2000
                });
            }

        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row m-md-3 m-1'>
                                <div className='col-md-6 '>
                                    <div className='editor-container' style={{ border: '1px solid #ccc',height: '400px', overflow: 'auto'  }}>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                            style={{
                                                border: '1px solid #ccc',
                                                minHeight: '200px', // Set the minimum height
                                                maxHeight: '400px', // Set the maximum height
                                                overflowY: 'auto',  // Enable vertical scrolling if content overflows
                                              }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6 '>
                                    <label className='form-label'>Group Name</label>
                                    <select as='select' className='form-select' onChange={(e) => setSelectedFilter(e.target.value)} value={selectedFilter}>
                                        <option value=''>Select Group Name</option>
                                        {groupdetails.length !== 0 ? (
                                            groupdetails.map((data, index) => (
                                                <option key={index} value={data.group}>
                                                    {data.group}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">No groups available</option>
                                        )}
                                    </select>
                                </div>
                                
                                <div className='col-12 col-md-4 py-3'>
                                    <button className="btn defult-button mx-2 my-md-0 my-2" type="submit" onClick={handleSave}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
