import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { candidateget } from '../../../middleware/CandidateMiddleware';
import Pagination from '../../../component/Pagination';
import Cookies from 'js-cookie';

export default function PendingList(props) {

    const dispatch = useDispatch()
    const pending_data = props.data
    const deletefunction = props.deletedata
    const searchdata = props.search
    const filterdate= props.filter
    const [candidateModifideData, setCandidateModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
        candidateDataList: null,
        currentPage: 1,
        totalCount: 0,
        pageSize: 10,
        search: '',   
        testMonth:''

    });


    useEffect(() => {
        if (pending_data?.status === 200) {
            setCandidateModifideData((prs) => {
                return {
                    ...prs,
                    candidateDataList: pending_data?.data,
                    deleteModel: false,
                    deleteIdData: null,
                    totalCount: pending_data?.data?.totalCandidates,
                }
            })
        }
    }, [pending_data])

    useEffect(() => {
        if (candidateModifideData.currentPage) {
            getoveralldata();
        }
    }, [candidateModifideData.currentPage, searchdata,filterdate])
    const setCurrentPage = (page) => {
        setCandidateModifideData((prs) => {
            return {
                ...prs,
                currentPage: page,
            }
        })

    }

    const getoveralldata = () => {
        const obj = {};
        obj['pageNo'] = candidateModifideData.currentPage;
        obj['count'] = candidateModifideData.pageSize;
        obj['status'] = 'pending';
        if (searchdata) {
            obj['searchText'] = searchdata;
        }
        if (filterdate){
            obj['testMonth'] = filterdate
        }
        dispatch(candidateget(obj))
    }
    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const role = Cookies.get("role");
    return (
        <>
            <div className='table-responsive'>
                <table className="table table-striped-custom table-hover">
                    <thead className='table-head'>
                        <tr>
                            <th >S.No</th>
                            <th>Name</th>
                            <th className='text-nowrap'>Passport No</th>
                            {role === "admin" ?
                                <>
                                    <th className='text-nowrap'>Test Month</th>
                                    <th className='text-nowrap'>Result</th>
                                    <th className='text-nowrap'>Employeer status</th>
                                </> : ''}
                            <th>Height</th>
                            <th>Weight</th>
                            <th className='text-nowrap'>T shirt Size</th>
                            <th className='text-nowrap'>Shoe Size</th>
                            <th className='text-center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidateModifideData?.candidateDataList?.candidates.length !== 0 ?
                            (candidateModifideData && candidateModifideData?.candidateDataList?.candidates.map((candidate, index) => (
                                <tr key={index}>
                                    <td className='text-center'>{index + 1 + (candidateModifideData.currentPage - 1) * candidateModifideData.pageSize}</td>
                                    <td >{candidate.name}</td>
                                    <td >{candidate.passportNumber}</td>
                                    {role === "admin" ?
                                        <>
                                            <td>{candidate.testMonth !== null ? formatDate(candidate.testMonth) : ''}</td>                                          
                                            <td>{candidate.status}</td>
                                            <td>
                                                {candidate.status === 'IPA' && candidate.employerName}
                                                {candidate.status === 'RELEASED' && candidate.releaseDate}
                                                {candidate.status === 'RESULT' && candidate.result}
                                            </td>

                                        </>
                                        : ''}
                                    <td className='text-center'>{candidate.height}</td>
                                    <td className='text-center'>{candidate.weight}</td>
                                    <td className='text-center'>{candidate.tshirtSize}</td>
                                    <td className='text-center'>{candidate.shoeSize}</td>
                                    <td>
                                        <div className='d-flex'>
                                            <Button className='table-viewcustom-button'><Link to={`/candidate/edit/${candidate.id}`}><i className="fa fa-eye px-3" ></i></Link></Button>
                                            <Button className='table-custom-button' onClick={() => setCandidateModifideData((prs) => {
                                                return {
                                                    ...prs,
                                                    deleteIdData: candidate,
                                                    deleteModel: true
                                                };
                                            })
                                            }

                                            ><i className='fa fa-trash px-3'></i></Button>

                                        </div>
                                    </td>
                                </tr>
                            ))) :
                            <tr>
                                <td colSpan="12" className='text-center'>No data available</td>
                            </tr>
                        }
                    </tbody>

                </table>
               
            </div>

            {candidateModifideData?.totalCount > 0 ?
                <Pagination
                    className="pagination-bar"
                    currentPage={candidateModifideData.currentPage}
                    totalCount={candidateModifideData.totalCount}
                    pageSize={candidateModifideData.pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                /> : ''
            }

            <Modal show={candidateModifideData.deleteModel} onClick={() => setCandidateModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this candidate <b>{candidateModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCandidateModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" onClick={() => deletefunction(candidateModifideData?.deleteIdData?.id)}>
                    Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
