import { createSlice } from "@reduxjs/toolkit";
import { staffreportget, tradereportget } from "../middleware/AttendancereportMiddleware";

const StaffreportSlice = createSlice({
    name: 'Staffreport',
    initialState: {
        loading: false,
        report: null,
        error: null,  
        tradereport:null     
    },
    extraReducers: (builder) => {
        builder
           
            .addCase(staffreportget.pending, (state) => {
                state.loading = true;
            })
            .addCase(staffreportget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.report = action.payload
                
            })
            .addCase(staffreportget.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.report = null;
            })   
            
            

            .addCase(tradereportget.pending, (state) => {
                state.loading = true;
            })
            .addCase(tradereportget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.tradereport = action.payload
                
            })
            .addCase(tradereportget.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.tradereport = null;
            })  

    }
})



export default StaffreportSlice.reducer
