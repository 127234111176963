import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiDelete, apiGet, apiPost, apiPut } from '../service/apiService';





export const candidateadd = createAsyncThunk("Newcandidateadd", async (payload) => {
    const ress = await apiPost(endPoint.candidate , payload, "");
    return ress;
});


export const candidateget = createAsyncThunk("candidateget",async (payload) => {
        const response = await apiGet(endPoint.candidate , payload, "");
        return response
    }
)

export const candidatdelete =  createAsyncThunk("candidatedelete",async (payload) => {
    const response = await apiDelete(endPoint.candidate + '/' + payload)
    return response
})

export const candidategetbyid = createAsyncThunk("candidategetbyid",async(payload)=>{
    const response = await apiGet(endPoint.candidate + "/" + payload)
    return response

})

export const candidateupdate = createAsyncThunk("candidatupdate",async (payload)=>{
    const response = await apiPut(endPoint.candidate + "/"+payload?.id ,payload.data)
    return response
})