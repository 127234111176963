import React, { useEffect, useState } from 'react'
import Header from '../../../Navbar/Header'
import Sidebar from '../../sidebar/Sidebar'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { attendanceLabel, candidateErrorLabel } from '../../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { attendance_staff_crate, attendance_staff_delete, attendance_staff_get } from '../../../../middleware/AttendancestaffMiddleware';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
export default function Attendancestaff() {
    const dispatch = useDispatch()

    const categorystaffdata = useSelector(state => state.Attendancestaff)
    const [categorystaffmodifideData, setCategorystaffmodifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
    });
    const [staff, setStaff] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(""); // Step 1: Selected category state
    const filteredStaff = staff.filter((data) =>
        selectedCategory ? data.category === selectedCategory : true
    );

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(candidateErrorLabel.name_required),
        contactNumber: Yup.string().required(attendanceLabel.number_required),
        category: Yup.string().required(attendanceLabel.category_required),
    })

    const handlesubmit = async (values, formik) => {
        let response = await dispatch(attendance_staff_crate(values));
        const isSuccess = response?.payload?.status === 200;
        const messageType = isSuccess ? "success" : "error";

        toast(response?.payload?.data?.message, {
            type: messageType,
            autoClose: 2000
        });

        if (isSuccess) {
            const fieldsToReset = ['name', 'category', 'contactNumber'];

            fieldsToReset.forEach(field => {
                formik.setFieldValue(field, '');
                formik.setFieldTouched(field, false);
            });
            dispatch(attendance_staff_get())

        }
    };
    useEffect(() => {
        dispatch(attendance_staff_get())
    }, [])

    useEffect(() => {
        if (categorystaffdata?.staffdata?.status == 200) {
            setStaff(categorystaffdata?.staffdata?.data?.staff || []);
        }
    }, [categorystaffdata])

    const categorystaff_delete = async (staffId) => {
        const response = await dispatch(attendance_staff_delete(staffId));
        if (response?.payload?.status === 200) {
           

            toast(response?.payload?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            dispatch(attendance_staff_get())
        } else {
            toast(response?.payload?.response?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ name: '', contactNumber: '', category: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handlesubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className='row px-4 mt-2'>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Staff Name</label>
                                                <Field type='text' className='form-control' placeholder='Enter Staff Name' name='name' />
                                                <ErrorMessage name='name' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Staff Category</label>

                                                <Field as="select" className='form-select' name='category'>
                                                    <option value="" selected hidden label="Select an option" />
                                                    <option value="Security">Security</option>
                                                    <option value="Cooking">Cooking</option>
                                                    <option value="Driver">Driver</option>
                                                    <option value="Manager">Manager</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="Trainer">Trainer</option>
                                                    <option value="Management">Management</option>
                                                    <option value="Store">Store</option>
                                                    <option value="TrainingManager">Training Manager</option>
                                                    <option value="Office">Office</option>
                                                </Field>
                                                <ErrorMessage name='category' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Staff Contact Number</label>
                                                <Field type='text' className='form-control' placeholder='Enter Staff Name' name='contactNumber' />
                                                <ErrorMessage name='contactNumber' component='div' className="text-danger" />
                                            </div>
                                        </div>
                                        <div className=' m-4'>
                                            <button className="btn defult-button" type="submit">Submit</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className='card custom-card mt-3'>
                            <div className='table-responsive border border-1'>

                                <div className='col-md-3 m-3'>
                                    <select
                                        as="select"
                                        className='form-select'
                                        name='category'
                                        value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    >
                                        <option value="">All Data</option>
                                        <option value="Security">Security</option>
                                        <option value="Cooking">Cooking</option>
                                        <option value="Driver">Driver</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Trainer">Trainer</option>
                                        <option value="Management">Management</option>
                                        <option value="Store">Store</option>
                                        <option value="TrainingManager">Training Manager</option>
                                        <option value="Office">Office</option>
                                    </select>
                                </div>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='text-center'>S.No</th>
                                            <th className='text-center'>Staff Name</th>
                                            <th className='text-center'>Staff Category</th>
                                            <th className='text-center'>Staff Contact Number</th>
                                            <th className='text-center'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredStaff && filteredStaff.length > 0 ? (
                                            filteredStaff.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td className='text-center'> {data.name}</td>
                                                    <td className='text-center'> {data.category}</td>
                                                    <td className='text-center'> {data.contactNumber}</td>
                                                    <td>
                                                        <Button className='table-custom-button my-1' variant="primary" onClick={() => setCategorystaffmodifideData((prs) => {
                                                            return {
                                                                ...prs,
                                                                deleteIdData: data,
                                                                deleteModel: true
                                                            };
                                                        })
                                                        }
                                                        ><i className='fa fa-trash px-3'></i></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className='text-center'>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={categorystaffmodifideData.deleteModel} onClick={() => setCategorystaffmodifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this staff <b>{categorystaffmodifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCategorystaffmodifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button"
                        onClick={() => categorystaff_delete(categorystaffmodifideData?.deleteIdData?.id)}

                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
