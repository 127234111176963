import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPut } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const remarkget = createAsyncThunk("remarkget",async (payload) => {
    const response = await apiGet(endPoint.remark , payload, "");
    return response
}
)



export const statusupate = createAsyncThunk("statusupdate",async (payload) => {
    const response = await apiPut(endPoint.status_update  + "/"+payload?.id, payload.data, "");
    
    return response
}
)