import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/account/Login";

import './App.css'
import Candidatelist from "./pages/layouts/candidate/Candidatelist";
import CandidateAdd from "./pages/layouts/candidate/CandidateAdd";
import BulkAdd from "./pages/layouts/bulkadd/BulkAdd";
import Staff from "./pages/layouts/staff/Staff";
import StaffAdd from "./pages/layouts/staff/StaffAdd";
import Dashboard from "./pages/layouts/dashboard/Dashboard"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { useEffect, useState } from "react";
import StaffEditForm from "./pages/layouts/staff/StaffEditForm";
import CandidateEdit from "./pages/layouts/candidate/CandidateEdit";

import Cookies from 'js-cookie';
import ChatPage from "./pages/layouts/chat/Chat";
import Japan from "./pages/layouts/japan/Japan";
import Remarks from "./pages/layouts/remarks/Remarks";
import JapanEdit from "./pages/layouts/japan/JapanEdit";
import Profile from "./pages/account/profile";
import NotificationModal from "./pages/modal/NotificationModal";
import Category from "./pages/layouts/attendance/Category";
import Attendancestaff from "./pages/layouts/attendance/staff/Attendancestaff";
import Trade from "./pages/layouts/attendance/trade/Trade";
import Report from "./pages/layouts/attendance/report/Report";
import Group from "./pages/layouts/announcement/Group";
import Employee from "./pages/layouts/announcement/Employee/Employee";
import Mapping from "./pages/layouts/announcement/mapping/Mapping";
import Database from "./pages/layouts/announcement/Employee/Database";
import Message from "./pages/layouts/announcement/message/Message";
import Approval from "./pages/layouts/announcement/message/Approval";
import Resource from "./pages/layouts/resources/Resource";
import ResourceEdit from "./pages/layouts/resources/ResourceEdit";
import Israel from "./pages/layouts/israel/Israel";
import IsraelEdit from "./pages/layouts/israel/IsraelEdit";
import { Pdf } from "./pages/layouts/pdf/Pdf";
import { Addpdf } from "./pages/layouts/pdf/PdfAdd";
import { PdfEdit } from "./pages/layouts/pdf/PdfEdit";
import Singapore from "./pages/layouts/singapore/Singapore";
import SingaporeEdit from "./pages/layouts/singapore/SingaporeEdit";

function App() {
  const [role,setRole] = useState('')
  const [showModal, setShowModal] = useState(false);
  
  const navigate = useNavigate()

  const onClose = () => {
    setShowModal(false);
  }
  axios.interceptors.request.use(
    (request) => {
      const token = Cookies.get("access_token")
      console.log(token)
      if (!token){
        navigate('/login')
      }
      request.headers.Authorization = `${token}`;   
      return request;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (request) => {
      return request;
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );


  console.log(Cookies.get("access_token"));
  
  useEffect(() => {
    const token = Cookies.get("access_token");
    setRole(Cookies.get("role"));
    console.log(token);
    console.log(role)
    if (!token) {
      navigate("/login");
    }


  }, []);


  useEffect(() => {
    const triggerEvery30Minutes = () => {
      if (!window.location.href.includes('login')){
        setShowModal(true); // Replace with your desired action
      } 
    };

    const calculateTimeUntilNextInterval = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();

      // Calculate the time until the next interval
      let timeUntilNextInterval = 0;

      // Calculate the minutes remaining for the next half-hour interval
      if (currentMinutes < 30) {
        timeUntilNextInterval = (30 - currentMinutes) * 60 * 1000;
      } else {
        // If it's already past 30 minutes, calculate time until the next hour
        timeUntilNextInterval = (60 - currentMinutes) * 60 * 1000;
      }
      return timeUntilNextInterval;
    };

    // Calculate the time until the next interval
    const timeUntilNextInterval = calculateTimeUntilNextInterval();

    // Set up the initial timeout to align with the next interval
    const initialTimeoutId = setTimeout(() => {
      triggerEvery30Minutes();

      // Set up the interval to run every 30 minutes
      const intervalId = setInterval(triggerEvery30Minutes, 30 * 60 * 1000);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, timeUntilNextInterval);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  }, []);


  return (
    <div>
    
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path='profile' element={<Profile />} />
        {/* <Route path="israel">
            <Route path="" element={<Israel/>}/>
            <Route path="edit/:id" element={<IsraelEdit/>}/>
          </Route> */}
        <Route path="" >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard">
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path="candidate">
            <Route path="" element={<Candidatelist />} />
            <Route path="new" element={<CandidateAdd />} />
            <Route path="edit/:id" element={<CandidateEdit />} />
          </Route>
          {/* <Route path="mapping">
          <Route path="" element={<Mapping/>}/>
          </Route> */}
          {role !== 'staff' ? 
          <Route path="user">
            <Route path="" element={<Staff/>}/>
            <Route path="new" element={<StaffAdd/>}/>
            <Route path="edit/:id" element={<StaffEditForm />} />
          </Route>:''}
          <Route path="bulkadd">
            <Route path="" element={<BulkAdd/>}/>

          </Route>
          <Route path="chat">
            <Route path="" element={<ChatPage/>} />
          </Route>
          
         
          <Route path="japan">
            <Route path="" element={<Japan/>}/>
            <Route path="edit/:id" element={<JapanEdit/>}/>
          </Route>
          {/* <Route path="singapore">
            <Route path="" element={<Singapore/>}/>
            <Route path="edit/:id" element={<SingaporeEdit/>}/>
          </Route> */}
          <Route path="resource">
            <Route path="" element={<Resource/>}/>
            <Route path="edit/:id" element={<ResourceEdit/>}/>
          </Route>
          
          
          {role !== 'staff' ? 
          <>
          <Route path ="remarks">
            <Route path="" element={<Remarks/>}/>
          </Route>
          
            </>
          :''}
          {/* <Route path="admission">
             <Route path="" element={<Pdf/>}/>
             <Route path="new" element={<Addpdf/>}/>
             <Route path="edit/:id" element={<PdfEdit/>}/>
            </Route> */}
          <Route path ="Attendance">
            <Route path="category" element={<Category/>}/>
            <Route path="staffs" element={<Attendancestaff/>}/>
            <Route path="trade" element={<Trade/>}/>
            <Route path="report" element={<Report/>}/>
          </Route>


          <Route path ="announcement">
            <Route path="group" element={<Group/>}/>
            <Route path="employee" element={<Employee/>}/>
            <Route path="database" element={<Database/>}/>
            <Route path="message" element={<Message/>}/>
            <Route path="approval" element={<Approval/>}/>
           
          </Route>



          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Route>
      </Routes>
 
 
     

      <ToastContainer position="top-right" theme="colored" autoClose={100}  />
      <NotificationModal show={showModal} onClose={onClose} />

    </div>
  );
}

export default App;
