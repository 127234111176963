import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'

import { Field, Form, Formik } from 'formik'

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";

import { format } from 'date-fns';
import { singaporegetbyid } from '../../../middleware/SingaporeMiddleware';
export default function SingaporeEdit() {
    const dispatch = useDispatch()

    const singaporeData = useSelector(state => state.Singapore.getsingapore);

    const navigate = useNavigate()
    const [initialState, setInitialState] = useState({
        name: "", father_name: '', dob: '',
        email: '', contact: '', emergency_contact: '',
        passport_number: '', passport_expiry_date: '',

    })
   
    const cancelsubmit = () => {
        navigate(-1);
    }
    let { id } = useParams();

    useEffect(() => {
        dispatch(singaporegetbyid(id))
    }, [])

    useEffect(() => {
        if (singaporeData?.status === 200) {
            setInitialState(singaporeData?.data);
        }
    }, [singaporeData])


    
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    enableReinitialize
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>name</label>
                                                    <Field placeholder='User Name' className='form-control' name="name" value={values?.name} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Father Name</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="fathers_name" value={values?.fathers_name} />
                                                </div>
                                            </div>


                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Age</label>
                                                    <DatePicker placeholder='age' className='form-control' name="age" 
                                                    value={values?.age}
                                                    />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="email" value={values?.email} />
                                                </div>
                                            </div>




                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field placeholder='User Name' className='form-control' name="contact_number" value={values?.contact_number} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Address</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="address" value={values?.address} />
                                                </div>
                                            </div>



                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>State</label>
                                                    <Field placeholder='User Name' className='form-control' name="state" value={values?.state} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Disctict</label>
                                                    <DatePicker placeholder='User Name' className='form-control' name="district" 
                                                    value={values?.district}
                                                    />
                                                </div>
                                            </div>



                                            <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Education Qualification </label>
                                                    <Field placeholder='User Name' className='form-control' name="education_qualification" value={values?.education_qualification} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport</label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.passport} target="_blank"  rel="passport">
                                                        Download passport
                                                    </a>
                                                </div>
                                            </div>



                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Education Certificate</label>
                                                    <input placeholder='User Name' type="file" className='form-control' />
                                                    <a href={values.educational_certificate} target="_blank"  rel="educational_certificate">
                                                        Download educational certificate
                                                    </a>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>vaccination certificate</label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.vaccination_certificate} target="_blank"  rel="vaccination_certificate">
                                                        Download vaccination certificate
                                                    </a>
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Full Size Photo </label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.full_size_photo} target="_blank"  rel="full_size_photo">
                                                        Download full size photo
                                                    </a>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>

                                                </div>
                                            </div>

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>

                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
