import {configureStore} from '@reduxjs/toolkit'
import loginreducer from './LoginSlice'
import CandidateReducer from './CandidateSlice'
import StaffReducer from './StaffSlice'
import BulkaddReducer from './BulkaddSlice'
import NotificationReducer from './NotificationSlice'
import CountReducer from './CountSlice'
import ChatReducer from './ChatSlice'
import JapanReducer from './JapanSlice'
import RemarkReducer from './RemarkSlice'
import DashboardReducer from './DashboardSlice'
import AttendancestaffReducer from './AttendancestaffSlice'
import CategoryReducer from './AttendanceSlice'
import AttendancetradeReducer from './AttendancetradeSlice'
import AttendancestaffreportReducer from './AttendancereportSlice'
import AnnouncementgroupReducer from './AnnouncementSlice'
import AnnouncementuserReducer from './AnnouncementuserSlice'
import MappingReducer from './MappingSlice'
import AnnouncementmessageReducer from './AnnouncementmsgSlice'
import ResourceReducer from './ResourceSlice'
import IsraelReducer from './IsrealSlice';
import AdmissionReducer from './AdmissionSlice';
import SingaporeReducer from './SingaporeSlice';

export const store = configureStore({
    reducer:{
        Login:loginreducer,
        Candidate:CandidateReducer,
        Staffdata:StaffReducer,
        Bulkadd:BulkaddReducer,
        Notification:NotificationReducer,
        Count:CountReducer,
        Chat:ChatReducer,
        Japan:JapanReducer,
        Remark:RemarkReducer,
        Dashboard:DashboardReducer,
        Category:CategoryReducer,
        Attendancestaff:AttendancestaffReducer,
        Trade:AttendancetradeReducer,
        Staffreport:AttendancestaffreportReducer,
        Announcementgroup:AnnouncementgroupReducer,
        Announcementuser:AnnouncementuserReducer,
        Mapping:MappingReducer,
        Announcementmessage:AnnouncementmessageReducer,        
        Resource:ResourceReducer,    
        Israel:IsraelReducer,
        Admission:AdmissionReducer,
        Singapore:SingaporeReducer

    }

})