import React, { useState, useEffect } from "react";
import Header from "../../Navbar/Header";
import Sidebar from "../sidebar/Sidebar";
import { ErrorMessage, Field, Form, Formik } from 'formik';

import DatePicker from "react-datepicker";
import * as Yup from 'yup';

import { format } from 'date-fns';
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { admissionUpdate, admissiongetbyid } from "../../../middleware/AdmissionMiddleware";
export const PdfEdit = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const admissionData = useSelector(state => state.Admission.getadmission);
    const admissionDataupdate = useSelector(state => state.Admission.updateadmission);
    

    const cancelsubmit = () => {
        navigate(-1);
    }

    const admissionchema = Yup.object().shape({
        ad_fees: Yup.string().required('Ad Fees is Required'),
        admission_number: Yup.string().required('Admission number required'),
        trade: Yup.string().required('Trade is required'),
        name: Yup.string().required('Name is required'),
        passport_no: Yup.string().required('Passport No is required'),
        contact: Yup.string().required('Contact is required'),
    })



    useEffect(() => {
        if (admissionDataupdate?.status === 200) {
          toast(admissionDataupdate?.data?.message, {
            type: "success"
          });
          navigate(-1);
        } else {
          if (admissionDataupdate?.response?.status === 400 || !admissionDataupdate?.response?.status === 200) {
            toast(admissionDataupdate?.response?.data?.message, {
              type: "error"
            });
          }
        }
      }, [admissionDataupdate])

    const [initialState, setInitialState] = useState({
        ad_fees: '', admission_number: '', trade: '',
        name: '', dob: '', marital_status: '', nationality: '', religion: '', communication: '', contact: '',
        reference: '', height: '', weight: '', passport_no: '', date_of_issue: '',
        date_of_expiry: '', place_of_issue: '', applied: '', educational_qualification: '',
        additional_qualification: '', english_knowledge: '', working_experience: '', remarks: '', shoe_size: '',
        tshirt: '', dose: '', file: ''


    })
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }

    let { id } = useParams();

    useEffect(() => {
        dispatch(admissiongetbyid(id))
    }, [])


    useEffect(() => {
        if (admissionData?.status === 200) {
            setInitialState(admissionData?.data);

        }
    }, [admissionData])

    const Updatestaff = (values) => {
        const formData = new FormData();       
        formData.append('ad_fees', values.ad_fees);
        formData.append('admission_number', values.admission_number );
        formData.append('trade',values.trade);
        formData.append('name', values.name );
        if (values.dob) {
            const selectedDate = new Date(values.dob);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('dob', selectedDate.toISOString().split('T')[0]);
          }
        formData.append('marital_status', values.marital_status );
        formData.append('nationality', values.nationality );
        formData.append('religion', values.religion );
        formData.append('communication', values.communication);
        formData.append('contact', values.contact );
        formData.append('reference', values.reference);
        formData.append('height', values.height);
        formData.append('weight', values.weight);
        formData.append('passport_no', values.passport_no );
        if (values.date_of_issue) {
            const selectedDate = new Date(values.date_of_issue);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('date_of_issue', selectedDate.toISOString().split('T')[0]);
          }
          if (values.dateofexpiry) {
            const selectedDate = new Date(values.dateofexpiry);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('dateofexpiry', selectedDate.toISOString().split('T')[0]);
          }
        formData.append('place_of_issue', values.place_of_issue);
        formData.append('applied', values.applied );
        formData.append('educational_qualification', values.educational_qualification );
        formData.append('additional_qualification', values.additional_qualification );
        formData.append('english_knowledge', values.english_knowledge);
        formData.append('working_experience', values.working_experience );
        formData.append('remarks', values.remarks );
        formData.append('shoe_size', values.shoe_size );
        formData.append('tshirt', values.tshirt );
        formData.append('dose', values.dose );
        formData.append('file', values.file);
        console.log(formData)
        const param = { id: id, data: formData }
        dispatch(admissionUpdate(param))
    }
    return (

        <div className='container-fluid'>
            <div className='row'>
                <Header />
                <div className='col-2 p-0'>
                    <Sidebar />
                </div>
                <div className='col-10 mt-2 px-3'>
                    <div className='card custom-card'>
                        <div className='row mb-3 mt-2'>
                            <Formik
                                initialValues={initialState}
                                
                                onSubmit={Updatestaff}
                            >
                                  {({ values, setFieldValue }) => (
                                    < Form >
                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Ad Fees</label>
                                                <Field className='form-control' placeholder='Enter the Fees' name="ad_fees" type="number" value={values?.ad_fees}/>
                                                <ErrorMessage name='ad_fees' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Trade</label>
                                                <Field className='form-control' placeholder='Enter the trade Name' name="trade" />
                                                <ErrorMessage name='trade' component='div' className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Admission Number</label>
                                                <Field className='form-control' placeholder='Enter the Admission Number' name="admission_number" disabled />
                                                <ErrorMessage name='admission_number' component='div' className="text-danger" />
                                            </div>

                                        </div>


                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Name</label>
                                                <Field className='form-control' placeholder='Enter the Name' name="name" value={values?.name}/>
                                                <ErrorMessage name='name' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Date of birth</label>                                      
                                                <DatePicker placeholderText='Select the DOB' className='form-control' name="dob"
                                                    peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                    value={values?.dob ? format(new Date(values.dob), 'dd/MM/yyyy') : ''}
                                                    onChange={(date) => { setFieldValue('dob', date) }}

                                                />

                                                <ErrorMessage name='dob' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Marital Status</label>
                                                <Field as="select" className='form-select' name="marital_status">
                                                    <option value="" selected hidden label="Select marital Status" />
                                                    <option value="married">Married</option>
                                                    <option value="single">Single</option>
                                                    <option value="divorced">Divorced</option>
                                                    <option value="widower">Widower</option>
                                                </Field >

                                                <ErrorMessage name='marital_status' component='div' className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Religion</label>
                                                <Field as="select" className='form-select' name="religion">
                                                    <option value="" selected hidden label="Select religion" />
                                                    <option value="hindu">Hindu</option>
                                                    <option value="muslim">muslim</option>
                                                    <option value="christian">christian</option>
                                                </Field >
                                                <ErrorMessage name='religion' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Height</label>
                                                <Field className='form-control' placeholder='Enter the Height' name="height" />
                                                <ErrorMessage name='height' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Weight</label>
                                                <Field className='form-control' placeholder='Enter the Weight' name="weight" />
                                                <ErrorMessage name='weight' component='div' className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Communication</label>
                                                <Field className='form-control' placeholder='Enter the Communicatio' name="communication" />
                                                <ErrorMessage name='communication' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Contact</label>
                                                <Field className='form-control' placeholder='Enter the Contact number' name="contact" />
                                                <ErrorMessage name='contact' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Reference</label>
                                                <Field className='form-control' placeholder='Enter the Reference' name="reference" />
                                                <ErrorMessage name='reference' component='div' className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-2 mb-2 mb-sm-0'>
                                                <label className='form-label'>Passport No</label>
                                                <Field className='form-control' placeholder='Enter Passport No' name="passport_no" />
                                                <ErrorMessage name='passport_no' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-2 mb-2 mb-sm-0'>
                                                <label className='form-label'>Date of Issue (PP)</label>
                                               
                                                <DatePicker placeholderText='Select Date of Issue' className='form-control' name="date_of_issue"
                                                    peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                    value={values?.date_of_issue ? format(new Date(values.date_of_issue), 'dd/MM/yyyy') : ''}
                                                    onChange={(date) => { setFieldValue('date_of_issue', date) }}

                                                />
                                                <ErrorMessage name='date_of_issue' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-2 mb-2 mb-sm-0'>
                                                <label className='form-label'>Date of Expiry (PP)</label>
                                                
                                                 <DatePicker placeholderText='Select Date of Expiry' className='form-control' name="date_of_expiry"
                                                    peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                    value={values?.date_of_expiry ? format(new Date(values.date_of_expiry), 'dd/MM/yyyy') : ''}
                                                    onChange={(date) => { setFieldValue('date_of_expiry', date) }}

                                                />
                                                <ErrorMessage name='date_of_expiry' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-3 mb-2 mb-sm-0'>
                                                <label className='form-label'>Place of Issue</label>
                                                <Field className='form-control' placeholder='Place of Issue Name' name="place_of_issue" />
                                                <ErrorMessage name='place_of_issue' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-3 mb-2 mb-sm-0'>
                                                <label className='form-label'>Applied</label>
                                                <Field className='form-control' placeholder='Applied' name="applied" />
                                                <ErrorMessage name='applied' component='div' className="text-danger" />
                                            </div>
                                        </div>


                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Educational Qualification</label>
                                                <Field className='form-control' placeholder='Enter the Education Qualification' name="educational_qualification" />
                                                <ErrorMessage name='educational_qualification' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Additional Qualification</label>
                                                <Field className='form-control' placeholder='Enter the Additional Qualification' name="additional_qualification" />
                                                <ErrorMessage name='additional_qualification' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>English Knowledge</label>
                                                <Field className='form-control' placeholder='Enter the English Knowledge' name="english_knowledge" />
                                                <ErrorMessage name='english_knowledge' component='div' className="text-danger" />
                                            </div>
                                        </div>


                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Working Experience</label>
                                                <Field className='form-control' placeholder='Enter the Working Experience' name="working_experience" />
                                                <ErrorMessage name='working_experience' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Remarks</label>
                                                <Field className='form-control' placeholder='Enter the Remarks' name="remarks" />
                                                <ErrorMessage name='remarks' component='div' className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Shoe Size</label>
                                                <Field className='form-control' placeholder='Enter the Shoe Size' name="shoe_size" />
                                                <ErrorMessage name='shoe_size' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>T-Shirt Size</label>
                                                <Field className='form-control' placeholder='Enter the T-Shirt Size' name="tshirt" />
                                                <ErrorMessage name='tshirt' component='div' className="text-danger" />
                                            </div>
                                            <div className='col-sm-4 mb-2 mb-sm-0'>
                                                <label className='form-label'>Dose</label>
                                                <Field className='form-control' placeholder='Enter the Dose' name="dose" />
                                                <ErrorMessage name='dose' component='div' className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>File</label>
                                                <input
                                                    type="file"
                                                    className='form-control'
                                                    onChange={(event) => {
                                                        setFieldValue('file', event.currentTarget.files[0]);
                                                    }}
                                                />
                                                {values.file && (
                                                    <div className='mt-2 profileimage'>
                                                        {values.file.type.includes('image') ? (
                                                            <img
                                                                src={URL.createObjectURL(values.file)}
                                                                alt="Selected file"
                                                            />
                                                        ) : (
                                                            <embed
                                                                src={URL.createObjectURL(values.file)}
                                                                type="application/pdf"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='px-4 mt-3'>
                                            <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>
                                            <button className="btn defult-button ms-2" type='submit'>Submit</button>

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}