import React, { useEffect, useState } from "react";
import Header from "../../../Navbar/Header";
import Sidebar from "../../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";

import Staffreport from "./Staffreport";
import Tradereport from "./Tradereport";

import DatePicker from "react-datepicker";
import { trade_get } from "../../../../middleware/AttendancetradeMiddleware";

export default function Report() {
  const dispatch = useDispatch();

  const staffreport = useSelector((state) => state.Staffreport);
  const tradereport = useSelector((state) => state.Staffreport);
  const tradedata = useSelector(state => state.Trade)
  const [searchnumber, setSearchnumber] = useState(1);
  const [search, setSearch] = useState("");
  const [reportstaff, setReportstaff] = useState();
  const [reporttrade, setReporttrade] = useState();
  const [filterdate, setFilterdate] = useState(null);
  const [tofilterdate, setTofilterdate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tradecategory, setTradecategory] = useState("")
  const [downloaddata, setDownloaddata] = useState("")
  useEffect(() => {
    if (staffreport?.report?.status === 200) {
      setReportstaff(staffreport?.report?.data || []);
      setDownloaddata(staffreport?.report?.data?.file_link )

    }
  }, [staffreport]);

  useEffect(() => {
    if (tradereport?.tradereport?.status === 200) {
      setReporttrade(tradereport?.tradereport?.data || []);
      
      setDownloaddata(tradereport?.tradereport?.data?.file_link )
    }
    if (searchnumber === 2) {
      if (tradedata?.trade?.status === 200) {
        setTradecategory(tradedata?.trade?.data);
      }
    }
  }, [tradereport, tradedata]);


  const staff = () => {
    setFilterdate(null);
    setTofilterdate(null);
    setSelectedCategory("");
    setDownloaddata("");
    setSearchnumber(1);
    setSearch("");
  };
  const trade = () => {
    setFilterdate(null);
    setTofilterdate(null);
    setSelectedCategory("");
    setDownloaddata("");
    setSearchnumber(2);
    setSearch("");
    dispatch(trade_get())

  };

  const handleDownload = async () => {
    try {
      // Fetch the file data from the URL
      const response = await fetch(downloaddata);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
  
      // Create a blob object URL
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'staff_attendance.xlsx'; 
  
      link.click();
  
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading:', error);
    }
  }


  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
  const formattedfilterdate = filterdate ? formatDate(filterdate) : "";
  const formattedtofilterdate = tofilterdate ? formatDate(tofilterdate) : "";



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <div className="col-2 p-0">
            <Sidebar />
          </div>
          <div className="col-10 mt-2 px-md-3 px-1">
            <div className="card custom-card">
              <div className="row">
                <div className="col-md-3">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link custom-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => staff()}
                      >
                        Staff Report
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link custom-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => trade()}
                      >
                        Trade Report
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="col-md-9 d-flex justify-content-end align-items-center ">
                  <div className="row g-3 me-2 ms-2ms-md-0 me-md-4 d-block d-md-flex align-items-center">
                    <div className="col-12 col-md-2 px-md-0 px-2">
                      <div className="d-flex align-items-center">
                        <DatePicker
                          className="form-control"
                          placeholderText="From Date"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          selected={filterdate}
                          onChange={(date) => {
                            setFilterdate(date);
                          }}
                        />
                        <button
                          className="table-viewcustom-button p-1 btn btn-primary"
                          onClick={() => setFilterdate(null)}
                        >
                          <i className="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>

                    <div className="col-12 col-md-2">
                      <div className="d-flex align-items-center">
                        <DatePicker
                          className="form-control"
                          placeholderText="To Date"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          selected={tofilterdate}
                          onChange={(date) => {
                            setTofilterdate(date);
                          }}
                        />
                        <button
                          className="table-viewcustom-button p-1 btn btn-primary"
                          onClick={() => setTofilterdate(null)}
                        >
                          <i className="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>

                    <div className="col-12 col-md-3">
                      <input
                        className="m-2 form-control search-btn"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      {searchnumber === 1 ? (
                        <select
                          as="select"
                          className="form-select"
                          name="category"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                          <option value="">All Data</option>
                          <option value="Security">Security</option>
                          <option value="Cooking">Cooking</option>
                          <option value="Driver">Driver</option>
                          <option value="Manager">Manager</option>
                          <option value="Admin">Admin</option>
                          <option value="Trainer">Trainer</option>
                          <option value="Management">Management</option>
                          <option value="Store">Store</option>
                          <option value="TrainingManager">Training Manager</option>
                          <option value="Office">Office</option>
                        </select>
                      ) : (
                        <>
                          {Array.isArray(tradecategory) && tradecategory.length > 0 ? (
                            <select
                              as="select"
                              className="form-select"
                              name="category"
                              value={selectedCategory}
                              onChange={(e) => setSelectedCategory(e.target.value)} // Change this line
                            >
                              <option value="">Select Trade</option>
                              {tradecategory.map((data, index) => (
                                <option key={index} value={data.id}>
                                  {data.trade}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <select
                              as="select"
                              className="form-select"
                              name="category"

                            >
                              <option value="">Select Trade</option>

                            </select>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-12 col-md-2">
                      <button className="download_report m-2" onClick={handleDownload}>Download <i className="fa fa-download ms-2" aria-hidden="true"></i></button> </div>
                  </div>




                </div>
              </div>

              <div className="row">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {searchnumber === 1 ? (
                      <Staffreport
                        data={reportstaff?.records}
                        filter={formattedfilterdate}
                        todate={formattedtofilterdate}
                        filtercategory={selectedCategory}
                        search={searchnumber === 1 && search ? search : ""}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {searchnumber === 2 ? (
                      <Tradereport
                        data={reporttrade?.records}
                        filter={formattedfilterdate}
                        todate={formattedtofilterdate}
                        filtercategory={selectedCategory}
                        search={searchnumber === 2 && search ? search : ""}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
