import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { announcement_group_get } from '../../../../middleware/AnnouncementMiddleware';
import { announcement_user_delete, announcement_user_details } from '../../../../middleware/AnnouncementuserMiddleware';
import Header from '../../../Navbar/Header';
import Sidebar from '../../sidebar/Sidebar';
import { toast } from 'react-toastify';

import Modal from 'react-bootstrap/Modal';
export default function Database() {

    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState('');
    const [groupdetails, setGroupdetails] = useState('')

    const [employeedata, setEmployeedata] = useState('')
    const [employeeModifideData, setEmployeeModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
    });
    useEffect(() => {
        dispatch(announcement_group_get()).then(response => {
            if (response?.payload?.status === 200) {
                setGroupdetails(response?.payload?.data?.announcement_groups || []);
            }
        });
    }, []);

    const getEmployeeData = (filter = '') => {
        const obj = { groupFilter: filter };
        dispatch(announcement_user_details(obj)).then(response => {
            if (response?.payload?.status === 200) {
                setEmployeedata(response?.payload?.data?.records || []);
            }
        });
    };

    useEffect(() => {
        getEmployeeData(selectedFilter); // Fetch data when selectedFilter changes
    }, [selectedFilter]);


    const deleteEmployeeData = async (contactNumber) => {
        const response = await dispatch(announcement_user_delete(contactNumber));
        if (response?.payload?.status === 200) {
            toast(response?.payload?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            getEmployeeData(selectedFilter);

        } else {
            toast(response?.payload?.response?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }
    }



    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row m-2'>
                                <div className='col-4'>
                                    <select className='form-select' onChange={(e) => setSelectedFilter(e.target.value)} value={selectedFilter} >
                                        <option value=''>Select Filter Name</option>
                                        {groupdetails.length !== 0 ? (
                                            groupdetails.map((data, index) => (
                                                <option key={index} value={data?.group}>
                                                    {data?.group}
                                                </option>
                                            ))
                                        ) : (
                                            <option value=''>No groups available</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='table-responsive border border-1'>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='text-center'>S.No</th>
                                            <th className='text-center'>Group Name</th>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Contact Number</th>
                                            <th className='text-center'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeedata && employeedata.length > 0 ? (
                                            employeedata.map((data, index) => (
                                                <tr key={data?.id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td className='text-center'> {data?.groupName}</td>
                                                    <td className='text-center'> {data?.name}</td>
                                                    <td className='text-center'> {data?.contactNumber}</td>
                                                    <td>
                                                        <div className='text-center'>

                                                            <Button className='table-custom-button  my-1' variant="primary" onClick={() => setEmployeeModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: data,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className='text-center'>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={employeeModifideData.deleteModel} onClick={() => setEmployeeModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this number <b>{employeeModifideData?.deleteIdData?.contactNumber}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEmployeeModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button"
                        onClick={() => deleteEmployeeData(employeeModifideData?.deleteIdData?.id)}

                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
