import { createSlice} from '@reduxjs/toolkit'
import { pendingdata } from '../middleware/NotificationMiddleware';


const NotificationSlice = createSlice({
    name:'Notification',
    initialState:{
        loading:false,
        data:[],
        error:null
    },
    extraReducers:(builder)=>{
        builder
    .addCase(pendingdata.pending,(state)=>{
        state.loading = true;
        
    })
    .addCase(pendingdata.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.data=action.payload.data;
        state.error=null;
    })
    
    .addCase(pendingdata.rejected,(state,action)=>{
        state.loading = false;            
        state.error=action.error.message
    })
}

})

export default NotificationSlice.reducer


