import { createSlice } from '@reduxjs/toolkit';
import { admissionDelete, admissionUpdate, admissionadd, admissionget, admissiongetbyid } from '../middleware/AdmissionMiddleware';


const AdmissionSlice = createSlice({
    name: 'admission',
    initialState: {
        loading: false,
        admission: null,
        error: null,
        deleteData:null,
        updateadmission:null,
        getadmission:null


    },
    reducers: {
        clearadmission: (state) => {
            state.admission = null;
            state.updateadmission = null
            state.getadmission = null
            state.deleteData = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(admissionadd.pending, (state) => {
                state.loading = true;
            })

            .addCase(admissionadd.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.admission = action.payload;
            })
            .addCase(admissionadd.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })



            .addCase(admissionget.pending, (state) => {
                state.loading = true;
                state.deleteData = null
            })
            .addCase(admissionget.fulfilled, (state, action) => {
                state.deleteData = null
                state.loading = false
                state.error = null;
                state.admission = action.payload                
                state.updateadmission = null
                state.getadmission = null
            })
            .addCase(admissionget.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })



            .addCase(admissionDelete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(admissionDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(admissionDelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })
            



            .addCase(admissionUpdate.pending, (state) => {
                state.loading = true
            })

            .addCase(admissionUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.updateadmission = action.payload
                state.getadmission = null
                state.error = null
            })
            .addCase(admissionUpdate.rejected, (state, action) => {
                state.error = action.payload.error
            })



            .addCase(admissiongetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(admissiongetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getadmission = action.payload
                state.error = null
                state.updateadmission=null
            })
            .addCase(admissiongetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })

    }
})



export const { clearadmission } = AdmissionSlice.actions


export default AdmissionSlice.reducer
