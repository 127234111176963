import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const titpget = createAsyncThunk("titpget",async (payload) => {
    const response = await apiGet(endPoint.japan , payload, "");
    return response
}
)


export const titpgetbyid = createAsyncThunk("titpgetbyid", async (payload) => {
    const response = await apiGet(endPoint.japan + "/" + payload)
    return response
})


export const titpDelete = createAsyncThunk("titpdelete", async (payload) => {
    const response = await apiDelete(endPoint.japan + '/' + payload)
    return response
})