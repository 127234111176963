import React, { useEffect, useRef, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import '../sidebar/Sidebar.css'
import Header from '../../Navbar/Header'
import { Formik, Form, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { bulkadd } from '../../../middleware/BulkaddMiddleware'
import { toast } from 'react-toastify'
import { clearOldState } from '../../../store/BulkaddSlice'
import * as Yup from 'yup';
import NotificationModal from '../../modal/NotificationModal'
export default function BulkAdd() {


    const { error, loading, csv_data } = useSelector((state) => state.Bulkadd);


    const [showModal, setShowModal] = useState(true);
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const fileInputRef = useRef(null);


    const validationSchema = Yup.object().shape({
        csvFile: Yup.mixed().required('CSV file is required'),
    });

    const dispatch = useDispatch()
    const handleSubmit = (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('file', values.csvFile);
        dispatch(bulkadd(formData))
        resetForm()
    }



    useEffect(() => {
        if (csv_data?.status == 201) {
            toast(csv_data?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            fileInputRef.current.value = '';
        } else {
            toast(csv_data?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }
        dispatch(clearOldState());
    }, [csv_data]);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 px-4 mt-2'>
                                <Formik
                                    initialValues={{ csvFile: null }}
                                    onSubmit={handleSubmit}
                                    validationSchema={validationSchema}

                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                        resetForm
                                    }) => (
                                        <Form>
                                            <div className='col-md-6  mt-2'>
                                                <label className='form-label'>Upload the Excel </label>
                                                <input ref={fileInputRef}
                                                    type='file'
                                                    accept='.xlsx, .xls'
                                                    className='form-control'
                                                    placeholder='Enter your name'
                                                    name='csvFile'
                                                    onChange={(event) => {
                                                        setFieldValue('csvFile', event.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage name='csvFile' component='div' className='text-danger' />


                                            </div>
                                            <div className='mt-3'>
                                                <button className='btn defult-button ms-2' type='submit' disabled={!values.csvFile || loading}>
                                                    Submit
                                                </button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                                <div className='mt-2'>
                                    <p><span className='text-danger'>Note *</span> : Passport Number, Name is required</p>
                                </div>
                                {loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </div>           
        </>
    )
}
