import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiGet } from '../service/apiService';


export const overallcount = createAsyncThunk("overallcount", async (payload) => {
    const response = await apiGet(endPoint.staffcount , payload, "");
    return response;
});

export const overallcountget = createAsyncThunk("overallcountget", async (payload) => {
    const response = await apiGet(endPoint.over_count , payload, "");
    return response;
});


export const admincount = createAsyncThunk("admincount", async (payload) => {
    const response = await apiGet(endPoint.admin_count , payload, "");
    return response;
});