import { createSlice } from "@reduxjs/toolkit";
import { announcement_approval_msg, announcement_get_approval_msg, announcement_msg_delete } from "../middleware/AnnouncementmsgMiddleware";


const AnnouncementmsgSlice = createSlice({
    name: 'Announcementmsg',
    initialState: {
        loading:false,
        approval:[],
        error:null,
    },
    
    extraReducers: (builder) => {
        builder

        .addCase(announcement_approval_msg.pending, (state) => {
            state.loading = true;
        })
        .addCase(announcement_approval_msg.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.approval=action.payload;
            
        })
        .addCase(announcement_approval_msg.rejected, (state, action) => {
            state.error = action.payload
            state.loading = false
            state.approval = [];
        })


        .addCase(announcement_get_approval_msg.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(announcement_get_approval_msg.fulfilled, (state, action) => {
            state.loading = false;
            state.approval = action.payload;
          })
          .addCase(announcement_get_approval_msg.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          })



          
        .addCase(announcement_msg_delete.pending, (state) => {
            state.loading = true
            state.deleteData = null
        })

        .addCase(announcement_msg_delete.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteData = action.payload
        })
        .addCase(announcement_msg_delete.rejected, (state, action) => {
            state.error = action.payload.error
            state.loading = false
        })


    }

})



export default AnnouncementmsgSlice.reducer
