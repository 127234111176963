import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet, apiPost } from "../service/apiService";
import { endPoint } from "../config/endPoint";




export const announcement_user_create = createAsyncThunk("announcement_user_create",async(payload)=> {
    const response = await apiPost(endPoint.announcement, payload,"");
    return response;
})

export const announcement_user_details = createAsyncThunk("announcement_user_details",async(payload)=>{
    const response = await apiGet(endPoint.announcement,payload,"");
    return response
})

export const announcement_user_delete = createAsyncThunk("announcement_user_delete", async(payload)=>{
    const response = await apiDelete(endPoint.announcement + '/' + payload)
    return response
})


export const announcement_bulkadd = createAsyncThunk("announcement_bulkadd", async (payload) => {
    const response = await apiPost(endPoint.announcement_buldadd , payload, "");
    return response;
});