import { createSlice } from "@reduxjs/toolkit";
import { announcement_group_create, announcement_group_delete, announcement_group_get } from "../middleware/AnnouncementMiddleware";


const AnnouncementSlice = createSlice({
    name: 'Announcement',
    initialState: {
        loading:false,
        group:null,
        error:null,
    },

    extraReducers: (builder) => {
        builder

        .addCase(announcement_group_create.pending, (state) => {
            state.loading = true;
        })
        .addCase(announcement_group_create.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.group = action.payload
            
        })
        .addCase(announcement_group_create.rejected, (state, action) => {
            state.error = action.payload
            state.loading = false
            state.group = [];
        })


        .addCase(announcement_group_get.pending, (state) => {
            state.loading = true;
        })
        .addCase(announcement_group_get.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.group = action.payload
        })
        .addCase(announcement_group_get.rejected, (state, action) => {
            state.error = action.payload
            state.loading = false
            state.group = [];
        })



        .addCase(announcement_group_delete.pending, (state) => {
            state.loading = true
            state.deleteData = null
        })

        .addCase(announcement_group_delete.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteData = action.payload
        })
        .addCase(announcement_group_delete.rejected, (state, action) => {
            state.error = action.payload.error
            state.loading = false
        })


    }

})




export default AnnouncementSlice.reducer
