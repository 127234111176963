import React, { useState, useEffect } from 'react'
import Header from '../../../Navbar/Header'
import Sidebar from '../../sidebar/Sidebar'
import { mapping_add, mapping_list } from '../../../../middleware/MappingMiddleware';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../component/Pagination';
import { announcement_group_get } from '../../../../middleware/AnnouncementMiddleware';
import { toast } from 'react-toastify';

import DatePicker from "react-datepicker";
export default function Mapping() {

    const dispatch = useDispatch()

    const [testmonth, setTestmonth] = useState(null);
    const candidateData = useSelector(state => state.Mapping);
    const [selectedItems, setSelectedItems] = useState([]);
    const [result,setResult] = useState('')

    useEffect(() => {
        dispatch(announcement_group_get())
    }, [])   

    const [search, setSearch] = useState()
    const [mappingdata, setMappingdata] = useState({
        mappingDataList: null,
        currentPage: 1,
        totalCount: 0,
        pageSize: 30,
        search: ''

    });

    useEffect(() => {
        if (candidateData?.mappingdata?.status === 200) {
            setMappingdata((prs) => {
                return {
                    ...prs,
                    mappingDataList: candidateData?.mappingdata?.data,
                    totalCount: candidateData?.mappingdata?.data?.totalCandidates,
                }
            })
        }
    }, [candidateData])



    useEffect(() => {
        if (mappingdata.currentPage || mappingdata.pageSize) {
            getoveralldata();
        }
    }, [mappingdata.currentPage, search, testmonth,result,mappingdata.pageSize])

    useEffect(() => {
        setSelectedItems([]);
    }, [mappingdata.currentPage]);


    const setCurrentPage = (page) => {
        setMappingdata((prs) => {
            return {
                ...prs,
                currentPage: page,

            }
        })
    }
    const setpageSize = (size) => {
        setMappingdata((prs) => {
            return {
                ...prs,
                pageSize: size,
            }
        })
    }

    const getoveralldata = () => {

        const obj = {};
        obj['pageNo'] = mappingdata.currentPage;
        obj['count'] = mappingdata.pageSize;
        if (search) {
            obj['searchText'] = search;
        }
        if (testmonth) {
            obj['testMonth'] = formatDate(testmonth)
        }
        if (result) {
            obj['result'] = result
        }
        dispatch(mapping_list(obj))
    }

    const handlesubmit = async () => {
        const mapping_added = {}
        mapping_added['records'] = selectedItems
        const response = await dispatch(mapping_add(mapping_added))


        const isSuccess = response?.payload?.status === 200;
        const messageType = isSuccess ? "success" : "error";

        toast(response?.payload?.data?.message, {
            type: messageType,
            autoClose: 2000,
        });
        if (isSuccess) {
            setSelectedItems([])
        }
    }
    function formatDate(date) {
        if (date) {
            const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date).toLowerCase();
            const year = date.getFullYear();
            return `${month}-${year}`;
        }
    }

    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };
    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row '>
                                <div className='col-md-3'>                                    
                                    <div className='d-md-flex p-3'>
                                        <button className="btn defult-button mx-2 my-md-0 my-2" type="submit" onClick={handlesubmit}>Send</button>
                                    </div>
                                </div>
                                <div className="col-md-9 d-flex justify-content-end align-items-center ">
                                    <div className="row g-3 me-2 ms-2ms-md-0 me-md-4 d-block d-md-flex align-items-center">
                                        <div className="col-12 col-md-3">
                                            <input
                                                className="m-2 form-control search-btn"
                                                placeholder="Search"
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="d-flex align-items-center">
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Test Month"
                                                    renderMonthContent={renderMonthContent}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    selected={testmonth}
                                                    onChange={(date) => {
                                                        setTestmonth(date);
                                                    }}
                                                />
                                                <button
                                                    className="table-viewcustom-button p-1 btn btn-primary"
                                                    onClick={() => setTestmonth(null)}
                                                >
                                                    <i className="fa fa-retweet" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <select as="select"
                                                className="form-select"
                                                name="result"
                                                value={result}
                                                onChange={(e) => setResult(e.target.value)}>
                                                <option value="">Select Result</option>
                                                <option value="pass">Pass</option>
                                                <option value="fail">Fail</option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <input
                                                className="m-2 form-control search-btn"
                                                placeholder="Enter the page count"
                                                type='number'
                                                onChange={(e) => setpageSize(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        mappingdata?.mappingDataList?.candidates.length !== 0 &&
                                                        selectedItems.length === mappingdata?.mappingDataList?.candidates.length
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedItems(
                                                                mappingdata?.mappingDataList?.candidates.map((data) => data.id)
                                                            );
                                                        } else {
                                                            setSelectedItems([]);
                                                        }
                                                    }}
                                                />
                                            </th>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Contact Number</th>
                                            <th>Test Month</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mappingdata?.mappingDataList?.candidates.length !== 0 ?
                                            (mappingdata?.mappingDataList?.candidates && mappingdata?.mappingDataList?.candidates.map((data, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(data.id)}
                                                            onChange={() => {
                                                                if (selectedItems.includes(data.id)) {
                                                                    setSelectedItems(selectedItems.filter((id) => id !== data.id));
                                                                } else {
                                                                    setSelectedItems([...selectedItems, data.id]);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td >{index + 1 + (mappingdata.currentPage - 1) * mappingdata.pageSize}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.contactNumber}</td>
                                                    <td>{data.testMonth}</td>
                                                    <td>{data.result}</td>

                                                </tr>
                                            ))) :
                                            <tr>
                                                <td colSpan="4" className='text-center'>No data available</td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {mappingdata?.totalCount > 0 ?
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={mappingdata.currentPage}
                                        totalCount={mappingdata.totalCount}
                                        pageSize={mappingdata.pageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
