import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../component/Pagination';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { resourceDelete, resourceget } from '../../../middleware/ResourceMiddleware';

export default function Resource() {
    const resourceData = useSelector(state => state.Resource);

    const [resourceModifideData, setresourceModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
        resourceDataList: null,
        currentPage: 1,
        totalCount: 0,
        pageSize: 10,
        search: ''

    });


    const [search, setSearch] = useState()
    const dispatch = useDispatch()



    useEffect(() => {
        console.log(resourceData)
        if (resourceData?.resource?.status === 200) {

            setresourceModifideData((prs) => {
                return {
                    ...prs,
                    resourceDataList: resourceData?.resource?.data,
                    deleteModel: false,
                    deleteIdData: null,
                    totalCount: resourceData?.resource?.data?.totalCandidates,

                }
            })

        }

        if (resourceData?.deleteData?.status === 200) {
            toast(resourceData?.deleteData?.data?.message, {
                type: "success",
                autoClose:2000
            });
            dispatch(resourceget())
        } else {
            toast(resourceData?.deleteData?.response?.data?.message, {
                type: "error",
                autoClose:2000
            });
        }



    }, [resourceData])


    useEffect(() => {
        if (resourceModifideData.currentPage) {
            getoveralldata();
        }
    }, [resourceModifideData.currentPage, search])


    const getoveralldata = () => {
        const obj = {};
        obj['pageNo'] = resourceModifideData.currentPage;
        obj['count'] = resourceModifideData.pageSize;
        if (search) {
            obj['searchText'] = search;
        }
        dispatch(resourceget(obj))
    }
    const setCurrentPage = (page) => {
        setresourceModifideData((prs) => {
            return {
                ...prs,
                currentPage: page,
            }
        })

    }


    const deleteStaffData = () => {
        dispatch(resourceDelete(resourceModifideData?.deleteIdData?.id))
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row'>
                                <div className='col-md-9'></div>
                                <div className='col-md-3 d-flex justify-content-end align-items-center p-2'>
                                    <input className='mx-2 form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table table-striped-custom table-hover">
                                    <thead className='table-head'>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th className='text-nowrap'>Passport No</th>
                                            <th>Email</th>
                                            <th>Contact</th>
                                            <th>Referral</th>
                                            <th className='text-nowrap'>Emergency Contact</th>
                                            <th className='text-nowrap'>FIle download</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resourceModifideData?.resourceDataList?.candidates.length !== 0 ?
                                            (resourceModifideData?.resourceDataList?.candidates && resourceModifideData?.resourceDataList?.candidates.map((data, index) => (
                                                <tr key={index}>
                                                    <td className='text-center'>{index + 1 + (resourceModifideData.currentPage - 1) * resourceModifideData.pageSize}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.passport_number}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.contact}</td>
                                                    <td>{data.referral}</td>
                                                    <td>{data.emergency_contact}</td>
                                                    <td><a href={data.file_download} target="_blank">Download File</a></td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <Button className='table-viewcustom-button my-1'><Link to={`/resource/edit/${data.id}`}><i className="fa fa-eye px-3" aria-hidden="true"></i></Link></Button>
                                                            <Button className='table-custom-button my-1' variant="primary" onClick={() => setresourceModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: data,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))) :
                                            <tr>
                                                <td colSpan="10" className='text-center'>No data available</td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                { resourceModifideData?.totalCount > 0 ?
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={resourceModifideData.currentPage}
                                        totalCount={resourceModifideData.totalCount}
                                        pageSize={resourceModifideData.pageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal show={resourceModifideData.deleteModel} onClick={() => setresourceModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this staff <b>{resourceModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setresourceModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" onClick={() => deleteStaffData()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
