import React, { useEffect, useState } from 'react';
import Header from '../../Navbar/Header';
import Sidebar from '../sidebar/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { admissionDelete, admissionget } from '../../../middleware/AdmissionMiddleware';
import { clearadmission } from '../../../store/AdmissionSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Pagination from '../../../component/Pagination';
export const Pdf = () => {

    const navicate = useNavigate()

    const adddata = () => {
        navicate('new')
    }

    const dispatch = useDispatch()
    const [search, setSearch] = useState()
    const admissionData = useSelector(state => state.Admission);

    const [admissionModifideData, setAdmissionModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
        admissionDataList: null,
        currentPage: 1,
        totalCount: 0,
        pageSize: 10,
        search: ''

    });

    useEffect(() => {

        if (admissionData?.admission?.status === 200) {
            setAdmissionModifideData((prs) => {
                return {
                    ...prs,
                    admissionDataList: admissionData?.admission?.data,
                    deleteModel: false,
                    deleteIdData: null,
                    totalCount: admissionData?.admission?.data?.total_admissions,

                }
            })

        }
        if (admissionData?.deleteData?.status === 200) {
            toast(admissionData?.deleteData?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            getoveralldata();
        } else if (admissionData?.deleteData?.message === "Network Error") {
            toast(admissionData?.deleteData?.message, {
                type: "error",
                autoClose: 2000
            });
        }
        else {
            toast(admissionData?.deleteData?.response?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }

    }, [dispatch, admissionData])


    useEffect(() => {
        if (admissionModifideData.currentPage) {
            getoveralldata();
        }
    }, [admissionModifideData.currentPage, search])

    const getoveralldata = () => {

        const obj = {};
        obj['pageNo'] = admissionModifideData.currentPage;
        obj['count'] = admissionModifideData.pageSize;
        if (search) {
            obj['searchText'] = search;
        }
        dispatch(admissionget(obj))
    }
    const deleteAdmissionData = () => {
        console.log(admissionModifideData?.deleteIdData)
        dispatch(admissionDelete(admissionModifideData?.deleteIdData?.id))
    }


    const setCurrentPage = (page) => {
        setAdmissionModifideData((prs) => {
            return {
                ...prs,
                currentPage: page,

            }
        })
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <Header />
                <div className='col-2 p-0'>
                    <Sidebar />
                </div>
                <div className='col-10 mt-2 px-3'>
                    <div className='card custom-card'>
                        <div className='row'>
                            <div className='col-md-6'></div>

                            <div className='col-md-6 d-flex justify-content-end align-items-center p-2'>
                                <input className='mx-2 form-control' placeholder='Search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className='btn defult-button defult-button-head mx-2' type="buttons" onClick={() => adddata()}>Add New</button>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table className="table table-striped-custom table-hover">
                                <thead className='table-head'>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Admission No</th>
                                        <th>Name</th>
                                        <th className='text-nowrap'>Passport No</th>
                                        <th>Contact</th>
                                        <th className='text-nowrap'>Trade</th>
                                        <th className='text-nowrap'>FIle download</th>
                                        <th className='text-center'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {admissionModifideData?.admissionDataList?.admissions.length !== 0 ?
                                        (admissionModifideData?.admissionDataList?.admissions && admissionModifideData?.admissionDataList?.admissions.map((data, index) => (
                                            <tr key={index}>
                                                <td className='text-center'>{index + 1 + (admissionModifideData.currentPage - 1) * admissionModifideData.pageSize}</td>
                                                <td>{data.admission_number}</td>
                                                <td>{data.name}</td>
                                                <td>{data.passport_no}</td>
                                                <td>{data.contact}</td>
                                                <td>{data.trade}</td>
                                                <td><a href={data.file_download} target="_blank">Download File</a></td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <Button className='table-viewcustom-button my-1'><Link to={`/admission/edit/${data.id}`}><i className="fa fa-eye px-3" aria-hidden="true"></i></Link></Button>
                                                        <Button className='table-custom-button my-1' variant="primary" onClick={() => setAdmissionModifideData((prs) => {
                                                            return {
                                                                ...prs,
                                                                deleteIdData: data,
                                                                deleteModel: true
                                                            };
                                                        })
                                                        }
                                                        ><i className='fa fa-trash px-3'></i></Button>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))) :
                                        <tr>
                                            <td colSpan="10" className='text-center'>No data available</td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            {admissionModifideData?.totalCount > 0 ?
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={admissionModifideData.currentPage}
                                    totalCount={admissionModifideData.totalCount}
                                    pageSize={admissionModifideData.pageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={admissionModifideData.deleteModel} onClick={() => setAdmissionModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this  <b>{admissionModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAdmissionModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" onClick={() => deleteAdmissionData()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}