import React, { useEffect, useState } from 'react'
import { logout } from '../../middleware/Middleware';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../../App.css'
import { Link, useNavigate } from 'react-router-dom';
import { clearstaff } from '../../store/StaffSlice';
import { staffgetbyid } from '../../middleware/StaffMiddleware';
export default function Header() {
  const dispatch = useDispatch()
  const staffData = useSelector(state => state.Staffdata);
  const [profile,setProfile]= useState('')
  const navigate = useNavigate()
  const role = Cookies.get("role");
  
  const userid = Cookies.get("user_id");


  
  useEffect(() => {
    dispatch(staffgetbyid(userid))
  }, [])

  useEffect(() => {
    if (staffData?.getstaff?.status === 200) {
      setProfile(staffData?.getstaff?.data?.profileImage)
    }
  }, [staffData])



  const handlesubmit = async () => {

    try {
      await dispatch(logout());

      Cookies.remove('access_token');
      Cookies.remove('role');
      Cookies.remove('user_id');
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
  const handleclick = () => {
    dispatch(clearstaff());

    navigate('/profile')
  }



  return (
    <div className='header'>
      <div className='row d-flex align-items-center '>
        <div className='col-3'>
          <div className="d-flex align-items-center">
            <div className='header-logo'>
              <img src={require('../../assests/logo.png')} alt='not found' />
            </div>

          </div>
        </div>
        <div className='col-6'>
          <h3 className='logo-text text-center'><em>Candidates Data Management System</em></h3>
          <h6 className='logo-text-small text-center'><em>Candidates Data Management System</em></h6>
        </div>
        <div className='col-3'>
          <div className='header-profile float-end'>

            <DropdownButton id="dropdown-basic-button" 

            title={profile == null ? <img src={require('../../assests/profile.jpg')} alt='not found' /> :
            <img src={profile} alt='not found' />
          }
            // title={<img src={require('../../assests/profile.jpg')} alt='not found' />} 
            
            
            
            className='customDropdownStyle'>
              {role === 'admin' ?
                <Dropdown.Item onClick={() => handleclick()} > Profile</Dropdown.Item>
                : ""}

              <Dropdown.Item onClick={() => handlesubmit()}>Logout</Dropdown.Item>
            </DropdownButton>


          </div>
        </div>
      </div>
    </div>
  )
}
