import React from 'react'
import { useEffect } from 'react';
import { staffreportget } from '../../../../middleware/AttendancereportMiddleware';
import { useDispatch } from 'react-redux';

export default function Staffreport(props) {

    const dispatch = useDispatch()
    const reportstaff = props.data

    const searchdata = props.search
    const filterdate = props.filter
    const filtertodate = props.todate
    const filtercategory = props.filtercategory

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }


    useEffect(() => {
        getoveralldata();
    }, [filterdate,searchdata,filtertodate,filtercategory])


    const getoveralldata = () => {
        const obj = {};
        if (searchdata) {
            obj['searchText'] = searchdata;
        }
        if (filterdate) {
            obj['from_date'] = filterdate
        }
        if (filtertodate){
            obj['to_date'] = filtertodate
        }
        if (filtercategory){
            obj['category'] = filtercategory
        }
        dispatch(staffreportget(obj))
    }



    return (
        <div>
            <div className='table-responsive'>
                <table className="table table-striped-custom table-hover">
                    <thead className='table-head'>
                        <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Staff Name</th>
                            <th>category</th>
                            <th>Morning Attendance</th>
                            <th>Morning Attendance By</th>
                            <th>Evening Attendance</th>
                            <th>Evening Attendance By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportstaff && reportstaff.length > 0 ? (
                            reportstaff.map((data, index) => (
                                <tr key={data.id}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(data.date)}</td>

                                    <td>{data.name}</td>
                                    <td>{data.category}</td>
                                    <td >{data.morning_checkin === true ? 'Present' : data.morning_checkin === false ? 'Absent' : '--'}</td>
                                    <td>{data.morning_created_by}</td> 
                                    <td>{data.evening_checkout === true ? 'Present' : data.evening_checkout === false ? 'Absent' : '--'}</td>
                                    <td>{data.evening_created_by}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className='text-center'>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
