import { createAsyncThunk } from "@reduxjs/toolkit";
import { endPoint } from "../config/endPoint";
import { apiDelete, apiGet, apiPost } from "../service/apiService";


export const trade_create = createAsyncThunk("trade_create",async (payload) => {
    const response = await apiPost(endPoint.trade , payload, "");
    return response
})

export const trade_get = createAsyncThunk("trade_get", async (payload) => {
    const response = await apiGet(endPoint.trade, payload,"");
    return response
})

export const trade_delete = createAsyncThunk("trade_delete", async (payload)=> {
    const response = await apiDelete(endPoint.trade + '/' + payload)
    return response
})