import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const singaporeget = createAsyncThunk("singaporeget",async (payload) => {
    const response = await apiGet(endPoint.singapore , payload, "");
    return response
}
)


export const singaporegetbyid = createAsyncThunk("singaporegetbyid", async (payload) => {
    const response = await apiGet(endPoint.singapore + "/" + payload)
    return response
})


export const singaporeDelete = createAsyncThunk("singaporeDelete", async (payload) => {
    const response = await apiDelete(endPoint.singapore + '/' + payload)
    return response
})