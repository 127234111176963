import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiDelete, apiGet, apiPost, apiPut } from '../service/apiService';

export const staffGet = createAsyncThunk("staffGet",async (payload) => {
        const response = await apiGet(endPoint.get_staff , payload, "");
        return response
    }
)

export const addGroup = createAsyncThunk("addGroup",async (payload) => {
        const response = await apiPost(endPoint.group , payload, "");
        return response
    }
)

export const editGroupId = createAsyncThunk("editGroupId",async (payload) => {
    const response = await apiPut(endPoint.group + "/"+payload?.groupName , payload.data, "");
    return response
}
)

export const groupGet = createAsyncThunk("groupGet",async (payload) => {
    const response = await apiGet(endPoint.group , payload, "");
    return response
}
)

export const groupGetId = createAsyncThunk("groupGetId",async (payload) => {
    const queryParams = new URLSearchParams({ groupName: payload }); // Create query parameter
    const response = await apiGet(`${endPoint.groupname}?${queryParams}`, "");
    return response;
}
)

export const groupDelId = createAsyncThunk("groupDelId",async (payload) => {
    const response = await apiDelete(endPoint.group + "/"+ payload.id, "");
    return response
}
)

export const addMsg = createAsyncThunk("addMsg",async (payload) => {
    const response = await apiPost(endPoint.send_message + "/"+ payload.id , payload.data, "");
    return response
}
)

export const deleteMsgInd = createAsyncThunk("deleteMsgInd",async (payload) => {
    const response = await apiDelete(endPoint.msg_delete + "/"+ payload.id, "");
    return response
}
)

export const deleteMsgGroup = createAsyncThunk("deleteMsgGroup",async (payload) => {
    const response = await apiDelete(endPoint.group_msg_delete + "/"+ payload.id, "");
    return response
}
)