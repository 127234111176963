import { createSlice } from "@reduxjs/toolkit";
import { singaporeDelete, singaporeget, singaporegetbyid, titpgetbyid } from "../middleware/SingaporeMiddleware";

const SingaporeSlice = createSlice({
    name: 'singapore',
    initialState: {
        loading: false,
        singapore: null,
        error: null,
        
        deleteData: null,
        getsingapore : null,
        updatesingapore:null
    },
    extraReducers: (builder) => {
        builder
           
            .addCase(singaporeget.pending, (state) => {
                state.loading = true;
                state.deleteData = null
            })

            .addCase(singaporeget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.singapore = action.payload
                
            })
            .addCase(singaporeget.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
                state.singapore = null;
            })



            .addCase(singaporegetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(singaporegetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getsingapore = action.payload
                state.error = null
                state.updatesingapore=null
            })
            .addCase(singaporegetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })



            .addCase(singaporeDelete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(singaporeDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(singaporeDelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })

    }
})



export default SingaporeSlice.reducer
