import { createSlice } from "@reduxjs/toolkit";
import { announcement_bulkadd, announcement_user_create, announcement_user_details } from "../middleware/AnnouncementuserMiddleware";
import { announcement_group_delete } from "../middleware/AnnouncementMiddleware";


const AnnouncementuserSlice = createSlice({
    name:"Announcementuser",
    initialState:{
        loading:false,
        user:null,
        error:null,
        csv_data:null
    },
    reducers: {
        clearOldState: (state) => {
            state.csv_data = null;
        },
    },
    extraReducers: (builder) => {
        builder

        .addCase(announcement_user_create.pending, (state)=>{
            state.loading = true
        })
        .addCase(announcement_user_create.fulfilled,(state,action)=>{
            
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        })
        .addCase(announcement_user_create.rejected,(state,action)=>{
            state.loading = false;
            state.user = null;
            state.error=action.payload;
        })


        .addCase(announcement_user_details.pending, (state)=>{
            state.loading = true
        })
        .addCase(announcement_user_details.fulfilled,(state,action)=>{
            
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        })
        .addCase(announcement_user_details.rejected,(state,action)=>{
            state.loading = false;
            state.user = null;
            state.error=action.payload;
        })


        .addCase(announcement_group_delete.pending, (state)=>{
            state.loading = true
            state.deleteData = null
        })
        .addCase(announcement_group_delete.fulfilled, (state,action)=>{
            state.loading = false
            state.deleteData = action.payload
        })
        .addCase(announcement_group_delete.rejected, (state,action)=>{
            state.loading = false
            state.error = action.payload.error
        })


        .addCase(announcement_bulkadd.pending,(state)=>{
            state.loading = true;
            state.csv_data = null
        })
        .addCase(announcement_bulkadd.fulfilled,(state,action)=>{
            state.loading = false;            
            state.csv_data=action.payload;
            state.error=null;
        })
        
        .addCase(announcement_bulkadd.rejected,(state,action)=>{
            state.loading = false;            
            state.error=action.error.message;        
            state.csv_data = null
        })

    }
})


export const { clearOldState } = AnnouncementuserSlice.actions
export default AnnouncementuserSlice.reducer