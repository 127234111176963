import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const resourceget = createAsyncThunk("titpget",async (payload) => {
    const response = await apiGet(endPoint.resource , payload, "");
    return response
}
)


export const resourcegetbyid = createAsyncThunk("titpgetbyid", async (payload) => {
    const response = await apiGet(endPoint.resource + "/" + payload)
    return response
})


export const resourceDelete = createAsyncThunk("titpdelete", async (payload) => {
    const response = await apiDelete(endPoint.resource + '/' + payload)
    return response
})