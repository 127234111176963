import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet, apiPost } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const attendance_staff_crate = createAsyncThunk("attendance_staff_crate",async (payload)=>{
    const response = await apiPost(endPoint.category_staff, payload,"");
    return response
})


export const attendance_staff_get = createAsyncThunk("attendance_staff_get", async (payload) => {
    const response = await apiGet(endPoint.category_staff, payload,"");
    return response
})

export const attendance_staff_delete = createAsyncThunk("attendance_staff_delete", async (payload) => {
    const response = await apiDelete(endPoint.category_staff + '/' + payload)
    return response
})