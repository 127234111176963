import { createSlice} from '@reduxjs/toolkit'
import { admincount, overallcount } from '../middleware/CountMiddleware';


const CountSlice = createSlice({
    name:'Count',
    initialState:{
        loading:false,
        count:null,
        error:null
    },
    extraReducers:(builder)=>{
        builder
    .addCase(overallcount.pending,(state)=>{
        state.loading = true;
        
    })
    .addCase(overallcount.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.count=action.payload;
        state.error=null;
    })
    
    .addCase(overallcount.rejected,(state,action)=>{
        state.loading = false;            
        state.error=action.error.message
        state.count=null;
    })



    .addCase(admincount.pending,(state)=>{
        state.loading = true;
        
    })
    .addCase(admincount.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.count=action.payload;
        state.error=null;
    })
    
    .addCase(admincount.rejected,(state,action)=>{
        state.loading = false;            
        state.error=action.error.message
        state.count=null;
    })


}

})

export default CountSlice.reducer


