import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet, apiPost } from "../service/apiService";
import { endPoint } from "../config/endPoint";



export const announcement_msg_send = createAsyncThunk("announcement_msg_send",async(payload)=> {
    const response  = await apiPost(endPoint.whatspp_send_message , payload, "");
    return response
})

export const announcement_approval_msg = createAsyncThunk("announcement_approval_msg",async(payload)=> {
    const response  = await apiPost(endPoint.msg_approval , payload, "");
    return response
})

export const announcement_get_approval_msg = createAsyncThunk("announcement_get_approval_msg",async(payload)=> {
    const response  = await apiGet(endPoint.content_approval , payload, "");
    return response
})


export const announcement_msg_check = createAsyncThunk("announcement_msg_check",async(payload)=> {
    console.log(payload)
    const response  = await apiGet(endPoint.get_status_by_body, payload);
    return response
})

export const announcement_msg_delete = createAsyncThunk("announcement_group_delete", async (payload) => {
    const response = await apiDelete(endPoint.delete_content_template + '?' + payload)
    return response
})
 
