import React from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { announcementLabel } from '../../../config/labels/ErrorLabel';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { announcement_group_create, announcement_group_delete, announcement_group_get } from '../../../middleware/AnnouncementMiddleware';
import { toast } from 'react-toastify';
import { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function Group() {
    const [groupModifideData, setGroupModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
    });
    const groupdata = useSelector(state => state.Announcementgroup)

    const [groupname, setGroupname] = useState();
    const dispatch = useDispatch()
    const validationSchema = Yup.object().shape({
        group: Yup.string().required(announcementLabel.group_required),
    })



    useEffect(() => {
        dispatch(announcement_group_get())
    }, [])




    useEffect(() => {
        if (groupdata?.group?.status == 200) {
            setGroupname(groupdata?.group?.data?.announcement_groups || []);
        }
    }, [groupdata])



    const handlesubmit = async (values, formik) => {
        const formData = new FormData()

        formData.append('group', values.group);
        // formData.append('image', values.image);
        let response = await dispatch(announcement_group_create(formData))

        console.log(response)
        if (response?.payload?.status === 201) {
            toast(response?.payload?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            formik.setFieldValue('group', '');
            formik.setFieldTouched('image', false)
            dispatch(announcement_group_get())
        }
        else if (response?.payload?.status !== 200) {
            toast(response?.payload?.data?.error, {
                type: "error",
                autoClose: 2000
            });
        }
    }


    const deleteStaffData = async(groupId) => {
        const response = await dispatch(announcement_group_delete(groupId));
        if (response?.payload?.status === 200) {
          toast(response?.payload?.data?.message, {
            type: "success",
            autoClose: 2000
          });
          dispatch(announcement_group_get())

        } else {
          toast(response?.payload?.response?.data?.message, {
            type: "error",
            autoClose: 2000
          });
        }
      };


    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ group: '', image: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handlesubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                }) => (
                                    <Form>
                                        <div className='row px-4 mt-2'>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Group Name</label>
                                                <Field type='text' className='form-control' placeholder='Enter Group Name' name='group' />
                                                <ErrorMessage name='group' component='div' className="text-danger" />
                                            </div>
                                            {/* <div className='col-sm-6'>
                                                <label className='form-label'>Passport</label>
                                                <input
                                                    type="file"
                                                    className='form-control'
                                                    onChange={(event) => {
                                                        setFieldValue('image', event.currentTarget.files[0]);
                                                    }}
                                                />
                                                {values.image && (
                                                    <div className='mt-2 profileimage'>
                                                        {values.image.type.includes('image') ? (
                                                            <img
                                                                src={URL.createObjectURL(values.image)}
                                                                alt="Selected image"
                                                            />
                                                        ) : ''}
                                                    </div>
                                                )}
                                            </div> */}

                                        </div>


                                        <div className=' m-4'>
                                            <button className="btn defult-button" type="submit">Submit</button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className='card custom-card mt-3'>
                            <div className='table-responsive border border-1'>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='text-center'>S.No</th>
                                            <th className='text-center'>Category</th>
                                            <th className='text-center'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupname && groupname.length > 0 ? (
                                            groupname.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td className='text-center'> {data.group}</td>
                                                    <td>
                                                        <div className='text-center'>

                                                            {/* <Button className='table-viewcustom-button my-1'><Link to={`/announcement/group/${data.id}`}><i className="fa fa-eye px-3" aria-hidden="true"></i></Link></Button>  */}
                                                            <Button className='table-custom-button  my-1' variant="primary" onClick={() => setGroupModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: data,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className='text-center'>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={groupModifideData.deleteModel} onClick={() => setGroupModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this  <b>{groupModifideData?.deleteIdData?.group}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setGroupModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button"
                        onClick={() => deleteStaffData(groupModifideData?.deleteIdData?.id)}

                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
