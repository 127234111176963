import { createSlice } from "@reduxjs/toolkit";
import { mapping_list } from "../middleware/MappingMiddleware";


const MappingSlice = createSlice({
    name:'MappingSlice',
    initialState:{
        loading:false,
        mappingdata:[],
        error:null
    },

    extraReducers: (builder) =>{
        builder


        .addCase(mapping_list.pending,(state)=>{
            state.loading = true;
        })
        .addCase(mapping_list.fulfilled,(state,action)=>{
            state.loading = true;
            state.mappingdata=action.payload;
            state.error=null;
        })
        .addCase(mapping_list.rejected,(state,action)=>{
            state.loading = true;
            state.error=action.payload;
            state.mappingdata = [];
        })
    }
})

export default MappingSlice.reducer