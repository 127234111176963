import { createSlice } from "@reduxjs/toolkit";
import { trade_create, trade_get } from "../middleware/AttendancetradeMiddleware";
import { attendance_staff_delete } from "../middleware/AttendancestaffMiddleware";

const AttendancetradeSlice = createSlice({
    name: 'Attendance',
    initialState: {
        loading:false,
        trade:null,
        error:null,        
        deleteData:null
    },
   
    extraReducers: (builder) => {
        builder
           
            .addCase(trade_create.pending, (state) => {
                state.loading = true;
            })
            .addCase(trade_create.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.trade = action.payload
                
            })
            .addCase(trade_create.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.trade = [];
            })


            .addCase(trade_get.pending, (state) => {
                state.loading = true;
            })
            .addCase(trade_get.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.trade = action.payload
                
            })
            .addCase(trade_get.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.trade = null;
            })



            .addCase(attendance_staff_delete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })
    
            .addCase(attendance_staff_delete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(attendance_staff_delete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })


        }
})



export default AttendancetradeSlice.reducer
