import {createSlice} from '@reduxjs/toolkit'
import { attendance_staff_crate, attendance_staff_delete, attendance_staff_get } from '../middleware/AttendancestaffMiddleware'



const AttendancestaffSlice = createSlice({
    name:"attendancestaff",
    initialState:{
        loading:false,
        staffdata:null,
        error:null,        
        deleteData:null
    },
    extraReducers:(builder)=>{
        builder

        .addCase(attendance_staff_crate.pending,(state)=>{
            state.loading=true
        })
        .addCase(attendance_staff_crate.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.staffdata = action.payload
            
        })
        .addCase(attendance_staff_crate.rejected,(state,action)=>{
            state.loading = false;
            state.staffdata = null;
            state.error=action.payload
        })



        .addCase(attendance_staff_get.pending,(state)=>{
            state.loading=true
        })
        .addCase(attendance_staff_get.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.staffdata = action.payload
            
        })
        .addCase(attendance_staff_get.rejected,(state,action)=>{
            state.loading = false;
            state.staffdata = null;
            state.error=action.payload
        })


        .addCase(attendance_staff_delete.pending, (state) => {
            state.loading = true
            state.deleteData = null
        })

        .addCase(attendance_staff_delete.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteData = action.payload
        })
        .addCase(attendance_staff_delete.rejected, (state, action) => {
            state.error = action.payload.error
            state.loading = false
        })

    }
})

export default AttendancestaffSlice.reducer