import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { pendingdata } from '../../middleware/NotificationMiddleware';
import { Link,useNavigate } from 'react-router-dom';

export default function NotificationModal({ show, onClose }) {

  const data = useSelector(state => state.Notification);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (show === true){
      dispatch(pendingdata("user12"))
    }
  }, [show]);

  const handleClose = () => {
    
      onClose();
  };

  const redirectCanditate = (id) => {
    handleClose();
    window.location.href = `/candidate/edit/${id}`;
    console.log(id)
  }

  const handlenavigation = () => {
    navigate('/candidate');
    handleClose(); 
    setTimeout(() => {
      document.getElementById("pills-profile-tab")?.click();
    }, 100);
  };
  return (
    <>
    {}
     {data && data?.data?.length > 0 && (
    
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pending Candidate Data</Modal.Title>
        </Modal.Header>
        <div className='table-responsive'>
          <table className='table table-striped-custom table-hover'>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Passport Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
    
              {data && data?.data.slice(0,10).map((candidate, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{candidate.name}</td>
                    <td>{candidate.passport_number}</td>
                    <td><span className='text-decoration-underline text-primary cursor-pointer' onClick={() => redirectCanditate(candidate.id)}>Edit</span></td>
                  </tr>
                )

              })}
            </tbody>

          </table>
          
          <div className='text-center'>
          <Button variant="primary" onClick={handlenavigation} >
            View More
          </Button>
          </div>
        </div>
       
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}
