import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';
import './Sidebar.css'

export default function Sidebar(props) {
  const location = useLocation();
  const currentPath = location.pathname;
  const isCandidateActive = location.pathname.includes('/candidate');
  const isuserActive = location.pathname.includes('/user')
  const isDashboard = location.pathname === '/' || location.pathname.includes('/dashboard');
  const isJapanctive = location.pathname.includes('/japan')
  // const isSingaporective = location.pathname.includes('/singapore')
  const isRemarks = location.pathname.includes('/remarks')
  // const isAstropdf = location.pathname.includes('/admission')
  const isStaffsActive = location.pathname.includes('/staffs')
  const isResourcective = location.pathname.includes('/resource')

  // const isIsraelactive = location.pathname.includes('/israel')
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  let role = Cookies.get("role");
  const [isAttendanceDropdownOpen, setIsAttendanceDropdownOpen] = useState(false);
  const [isAnnouncementDropdownOpen, setIsAnnouncementDropdownOpen] = useState(false);

  const [updateSidebar, setUpdateSidebar] = useState('');

  const handleAttendanceClick = () => {
    setIsAttendanceDropdownOpen(!isAttendanceDropdownOpen);
  };
  const handleAnnouncementClick = () => {
    setIsAnnouncementDropdownOpen(!isAnnouncementDropdownOpen);
  }

  return (

    <div className={`sidebar-bg d-flex flex-column ${sidebarClass}`}>
      <ul>

        <li className={isDashboard ? 'active' : ''}>
          <Link to='/dashboard'><i className='fas fa-home px-2'></i><span>Dashboard</span></Link>
        </li>
        
        {/* {role === 'israel' ?
          <li className={isIsraelactive ? 'active' : ''}>
            <Link to='/israel'><i className='fa fa-yen-sign px-2'></i><span>Israel</span></Link>
          </li> : ''} */}
        {(role === 'admin' || role === 'staff') && (
          <>
            <li className={isCandidateActive ? 'active' : ''}>
              <Link to='/candidate'><i className='fa fa-user-plus px-2'></i><span>Candidates</span></Link>
            </li>
            <li className={location.pathname === '/bulkadd' ? 'active' : ''}>
              <Link to='/bulkadd'><i className='fa fa-upload px-2'></i><span>Bulk Add</span></Link>
            </li>
            <li className={location.pathname === '/chat' ? 'active' : ''}>
              <Link to='/chat'><i className="fa fa-comment px-2"></i><span>Chat</span></Link>
            </li>
           
            {/* <li className={ isAstropdf ? 'active' : ''}>   
            {role === 'admin' ?          
             <Link to='/admission'><i className='fa fa-address-book px-2'></i><span>Admission</span></Link>:
             
             <Link to='/admission/new'><i className='fa fa-address-book px-2'></i><span>Admission</span></Link>}
             </li> */}

            {role === 'admin' && (
              <>
                <li className={isuserActive ? 'active' : ''}>
                  <Link to='/user'><i className='fa fa-user px-2'></i><span>Users</span></Link>
                </li>

                <li className={isJapanctive ? 'active' : ''}>
                  <Link to='/japan'><i className='fa fa-yen-sign px-2'></i><span>Japan</span></Link>
                </li>
                {/* <li className={isSingaporective ? 'active' : ''}>
                  <Link to='/singapore'><i className='fa fa-yen-sign px-2'></i><span>Singapore</span></Link>
                </li> */}
                <li className={isResourcective ? 'active' : ''}>
                  <Link to='/resource'><i className='fa fa-yen-sign px-2'></i><span>Astro Resources (RA9273)</span></Link>
                </li>
                {/* <li className={isIsraelactive ? 'active' : ''}>
                  <Link to='/israel'><i className='fa fa-yen-sign px-2'></i><span>Israel</span></Link>
                </li> */}

                <li className={`text-nowrap ${isRemarks ? 'active' : ''}`}>
                  <Link to='/remarks'><i className='fa fa-handshake px-2'></i><span>Placement/Release</span></Link>
                </li>
                <li className={currentPath.startsWith('/Attendance') ? 'active' : ''} onClick={() => (setUpdateSidebar("attendance"))}>
                  <p className='mb-0'>
                    <i className='fa fa-handshake px-2'></i>
                    <span >Attendance</span>
                  </p>
                  {updateSidebar === 'attendance' && (
                    <ul className="submenu ps-lg-5 ps-md-3 ps-0" >
                      <li><Link to='/Attendance/staffs'>Staff</Link></li>
                      <li><Link to='/Attendance/trade'>Trade</Link></li>
                      <li><Link to='/Attendance/report'>Report</Link></li>
                    </ul>
                  )}
                </li>
                <li className={currentPath.startsWith('/announcement') ? 'active' : ''} onClick={() => (setUpdateSidebar("announcement"))}>
                  <p className='mb-0'>
                    <i className='fa fa-handshake px-2'></i>
                    <span >Announcement</span>
                  </p>
                  {updateSidebar === 'announcement' && (
                    <ul className="submenu ps-lg-5 ps-md-3 ps-0" >
                      <li><Link to='/announcement/group'>Group</Link></li>
                      <li><Link to='/announcement/employee'>Employee Add</Link></li>
                      <li><Link to='/announcement/database'>Database</Link></li>
                      <li><Link to='/announcement/message'>Message</Link></li>
                      <li><Link to='/announcement/approval'>Approval</Link></li>

                    </ul>
                  )}
                </li>



              </>
            )}
          </>
        )}

        {role === 'manager' && (
          <>

            <li className={isRemarks ? 'active' : ''}>
              <Link to='/remarks'><i className='fa fa-users px-2'></i><span>Placement/Release</span></Link>
            </li>
            {/* <li className={ isAstropdf ? 'active' : ''}>   
            <Link to='/admission'><i className='fa fa-address-book px-2'></i><span>Admission</span></Link>:
            </li> */}
          </>
        )}
      </ul>




    </div>
  )
}
