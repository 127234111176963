import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { addMsg, groupGetId, deleteMsgInd, deleteMsgGroup } from '../../../middleware/ChatMiddleware';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { Modal, Button } from 'react-bootstrap';

export default function ChatMessage(props) {
    const [chatObj, setChatObj] = useState(props.probs)
    const [chatMsg, setMsg] = useState("")
    const [chatFile, setFile] = useState(null)
    const [fileAtta, setFileAtta] = useState(null);
    const [possportNo, setPassPort] = useState("");
    const [show, setShow] = useState(false);
    const [showInd, setShowInd] = useState(false);
    const { loadingchat, error, addMessage } = useSelector((state) => state.Chat);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [deviceTypeWts, setDeviceType] = useState('');
    const role = Cookies.get("role");
    const userid = Cookies.get("user_id");
    const [indMsgDelete, setMsgDelete] = useState(null);
    const fileForward = (fileUrl, fileName) => {
        const shareLink = `${deviceTypeWts}?text=${encodeURIComponent(`Download ${fileName} from: ${fileUrl}`)}`;
        window.open(shareLink);
    }
    const textForward = (text) => {
        const shareLink = `${deviceTypeWts}?text=${text}`;
        window.open(shareLink);
    }
    useEffect(() => {
        const checkDeviceType = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth <= 768) {
                setDeviceType('whatsapp://send');
            } else if (screenWidth <= 1024) {
                setDeviceType('https://web.whatsapp.com/send');
            } else {
                setDeviceType('https://web.whatsapp.com/send');
            }
        };

        checkDeviceType();

        window.addEventListener('resize', checkDeviceType);

        return () => {
            window.removeEventListener('resize', checkDeviceType);
        };
    }, []);

    const [openFile, setopenFile] = useState(false);

    useEffect(() => {
        console.log('2', isLoading);
    }, [isLoading]);

    const submitChat = async () => {
        setIsLoading(true);

        try {
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            const formData = new FormData();
            formData.append('file', fileAtta);
            formData.append('check_file', chatFile);
            formData.append('passport_no', possportNo);
            formData.append('message', chatMsg);

            let data = {
                id: chatObj?.groupName,
                data: formData
            }

            let res = await dispatch(addMsg(data));

            if (res.payload.status === 200 || res.payload.status === 201) {
                setIsLoading(false);
                setMsg("");
                let chatData = res.payload.data;
                setChatObj(res.payload.data);
            } else {
                toast(res.payload.data.error, {
                    type: "error",
                    autoClose: 2000
                });
            }

            let res1 = await dispatch(groupGetId(chatObj?.groupName));

            if (res1.payload.status === 200) {
                setChatObj(res1.payload.data);
            } else {
                toast(res1.payload.data.error, {
                    type: "error",
                    autoClose: 2000
                });
            }

            
            setFile(null);
            setFileAtta(null);
            setPassPort("");

        } catch (error) {
            console.error("Error:", error);
            // Handle the error and set isLoading to false if needed
        } 

        console.log('2', isLoading)
    }

    const updateMsg = (eve) => {
        setMsg(eve)
    }

    const setFileFun = (file) => {
        setFile(file);
    }

    const setFileAttaFun = (file) => {
        setFileAtta(file);
    }


    const deleteMsg = (id) => {
        setMsgDelete(id);
        setShowInd(true);
    }

    const setPassportNo = (val) => {
        setPassPort(val)
    }

    const onDelete = async () => {
        let data = {
            "id": chatObj?.groupName
        }
        let res = await dispatch(deleteMsgGroup(data));
        if (res.payload.status == 200) {
            setShow(false);
            toast("Message delete successfully", {
                type: "success",
                autoClose: 2000
            });
        } else {
            toast(res.payload.data.error, {
                type: "error",
                autoClose: 2000
            });
        }
        setMsgDelete(null);
        openChat();
    }

    const deleteGroup = () => {
        setShow(true)
    }

    const onDeleteInd = async () => {
        console.log(indMsgDelete)
        let data = {
            "id": indMsgDelete
        }
        let res = await dispatch(deleteMsgInd(data));
        if (res.payload.status == 200) {
            setShowInd(false);
            toast("Message delete successfully", {
                type: "success",
                autoClose: 2000
            });
        } else {
            toast(res.payload.data.error, {
                type: "error",
                autoClose: 2000
            });
        }
        setMsgDelete(null);
        openChat();
    }

    const openChat = async () => {
        let res = await dispatch(groupGetId(chatObj?.groupName));
        if (res.payload.status === 200) {
            setChatObj(res.payload.data)
        } else {
            toast(res.payload.data.error, {
                type: "error"
            });
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 message-box'>
                    {chatObj.messages ? chatObj.messages.map((v) => (
                        <div className="text-end py-1" key={v.message_id}>
                            <h5 className="text-danger text-capitalize" >{v.sender}</h5>

                            {v.message.indexOf("http") >= 0 ?
                                <div className="d-inline-block user-text-box p-2 position-relative cus-msg-menu">
                                    <div className="align-items-center user-share d-inline-flex">
                                        <span className="mb-0 px-2 d-inline-block">
                                            {v.message.indexOf("pdf") >= 0 ? (
                                                <iframe src={v.message} width="100%" height="500" frameBorder="0"></iframe>
                                            ) : v.message.indexOf("xlsx") >= 0 ? (
                                                
                                                <div>
                                                    <img src={require('../../../assests/xls.png')} alt='not found' />{v.file_path}
                                                    </div>
                                            ) : (
                                                <img className="cus-msg-image" src={v.message} />
                                            )}
                                        </span>

                                        <div className="">
                                            <p className="py-1 mb-1 user-text d-inline-flex">
                                                <span className="p-2 d-inline-block cus-options"> <i className="fa fa-ellipsis-v "> </i>
                                                    <ul className="position-absolute">
                                                        <li onClick={() => fileForward(v.message, v.file_path)}>Forward</li>
                                                        <li><a className="text-decoration-none" href={v.message} target="_blank" download>Download</a></li>
                                                        {userid == v.sender ? <li onClick={() => deleteMsg(v.message_id)}>Delete</li> : ""}

                                                    </ul>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div> : <div className="position-relative cus-msg-menu"><p className="py-1 mb-1 user-text d-inline-flex"><span className='p-2'>{v.message}</span>
                                    <span className="p-2 d-inline-block cus-options"> <i className="fa fa-ellipsis-v "> </i>
                                        <ul className="position-absolute">
                                            <li onClick={() => textForward(v.message)}>Forward</li>
                                            {userid == v.sender ? <li onClick={() => deleteMsg(v.message_id)}>Delete</li> : ""}
                                        </ul>
                                    </span></p>
                                </div>
                            }
                        </div>
                    )) : ""}
                </div>
            </div>
            <div className='row d-block d-md-flex align-items-center' >
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <textarea className="form-control" value={chatMsg} placeholder="Type a Message" onChange={(eve) => updateMsg(eve.target.value)} rows="2"></textarea>
                    </div>
                </div>
                <div className="col-12 col-md-4 my-2">
                    {
                        openFile ? <div className="d-flex">
                            {role === 'admin' ? <div className="form-group open-file">
                                <input accept=".pdf, .xls, .xlsx, .doc, .docx" onChange={(e) => setFileFun(e.target.files[0])} className="form-control form-control-file d-none" type="file" id="actual-btn" />
                                <label className="label-cus" htmlFor="actual-btn"><i className="fa fa-filter" aria-hidden="true"></i></label>
                                {chatFile ? <span className="cut-text">{chatFile.name}</span> : <span className='fw-bold'> Search Doc</span>}
                            </div> : ""}

                            <div className="form-group open-file ps-3">
                                <input onChange={(e) => setFileAttaFun(e.target.files[0])} className="form-control form-control-file d-none" type="file" id="actual-btn1" />
                                <label className="label-cus" htmlFor="actual-btn1"><i className="fa fa-paperclip" aria-hidden="true"></i></label>
                                {fileAtta ? <span className="cut-text">{fileAtta.name}</span> : <span className='fw-bold'> Attachment</span>}
                            </div>

                        </div> :
                            <div className="d-flex">
                                <div class="form-group">
                                    <input type="text" value={possportNo} onChange={(eve) => setPassportNo(eve.target.value)} class="form-control" placeholder="Passport No" />
                                </div>
                            </div>
                    }
                </div>
                <div className="col-12 col-md-3 text-end d-flex">
                    <button type="submit" onClick={() => setopenFile(!openFile)} className="btn btn-primary m-2">
                        {openFile ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                    </button>
                    {isLoading ? <button type="submit" className="btn btn-primary m-2">Loading</button> :
                        <button type="submit" onClick={() => { submitChat() }} className="btn btn-primary m-2">send</button>}
                    {role === 'admin' ? <button className="btn btn-danger m-2" onClick={() => deleteGroup()}> <i class="fa fa-trash" aria-hidden="true"></i> </button> : ""}
                </div>
            </div>

            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete all messages?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn btn-secondary" onClick={() => { setShow(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" className='model-save-button btn btn-primary' onClick={() => onDelete()} >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showInd}>
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this messages?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn btn-secondary" onClick={() => { setShow(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" className='model-save-button btn btn-primary' onClick={() => onDeleteInd()} >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}