export const LoginErrorLabel = {
    email_required: "Username is required",
    password_required: "Password is required",
};
export const candidateErrorLabel = {
    name_required : "Name is required",
    passport_number_required:"Passport number is required", 
    email_required:"Email is required",
    invalid_format:"Invalid Email format"
}

export const staffErrorLabel = {
    userid_required : "User Id is required",
    phone_number_required:"Phone Number is required",
}
export const remarksErrorLabel = {
    username_required : "Canditate Name is required",
    passport_number_required: "Canditate Passport is required"
}
export const attendanceLabel = {
    category_required:"Category name is required",
    number_required:"Contact number is required"
}

export const tradeLabel = {
    trade_required:"Trade name is required"
}

export const announcementLabel = {
    group_required:"Group name is required",
    name_required:"Name is required",
    approve_required:"Message is required"
}