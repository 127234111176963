import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const mapping_list = createAsyncThunk("mapping_list",async(payload)=>{
    const response = await apiGet(endPoint.mapping_get,payload,'')
    return response
})

export const mapping_add = createAsyncThunk("mapping_add",async(payload) => {
    const response = await apiPost(endPoint.mapping_add,payload,'')
    return response
})