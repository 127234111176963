import { createSlice } from "@reduxjs/toolkit";
import { israelDelete, israelget, israelgetbyid } from "../middleware/IsrealMiddleware";

const IsraelSlice = createSlice({
    name: 'israel',
    initialState: {
        loading: false,
        israel: null,
        error: null,
        
        deleteData: null,
        getisrael : null,
        updateisrael:null
    },
    extraReducers: (builder) => {
        builder
           
            .addCase(israelget.pending, (state) => {
                state.loading = true;
                state.deleteData = null
            })
            .addCase(israelget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.israel = action.payload
                
            })
            .addCase(israelget.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
                state.israel = null;
            })



            .addCase(israelgetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(israelgetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getisrael = action.payload
                state.error = null
                state.updateisrael=null
            })
            .addCase(israelgetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })



            .addCase(israelDelete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(israelDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(israelDelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })

    }
})



export default IsraelSlice.reducer
