import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiDelete, apiGet, apiPost, apiPut } from '../service/apiService';

export const staffadd = createAsyncThunk("staffadd", async (payload) => {
    const response = await apiPost(endPoint.staff, payload);
    return response;
});

export const staffget = createAsyncThunk("staffget", async (payload) => {
    const response = await apiGet(endPoint.staff, payload)
    return response
})

export const staffDelete = createAsyncThunk("staffdelete", async (payload) => {
    const response = await apiDelete(endPoint.staff + '/' + payload)
    return response
})


export const staffUpdate = createAsyncThunk("staffupdate",async (payload)=>{
    const response = await apiPut(endPoint.staff + "/"+payload?.id ,payload.data);
    return response;
})


export const staffgetbyid = createAsyncThunk("staffgetbyid", async (payload) => {
    const response = await apiGet(endPoint.staff + "/" + payload)
    return response
})
