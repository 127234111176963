import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Navbar/Header'
import Sidebar from '../../sidebar/Sidebar'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { announcementLabel, staffErrorLabel } from '../../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { announcement_group_get } from '../../../../middleware/AnnouncementMiddleware';
import { announcement_bulkadd, announcement_user_create, announcement_user_delete, announcement_user_details } from '../../../../middleware/AnnouncementuserMiddleware';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import { clearOldState } from '../../../../store/BulkaddSlice';
export default function Employee() {

    const dispatch = useDispatch();

    const { error, loading, csv_data } = useSelector((state) => state.Announcementuser);

    const [groupdetails, setGroupdetails] = useState('')

   
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(announcementLabel.name_required),
        contactNumber: Yup.string().required(staffErrorLabel.phone_number_required),
        groupName: Yup.string().required(announcementLabel.group_required),
    })

    const validationSchema1 = Yup.object().shape({
        csvFile: Yup.mixed().required('CSV file is required'),
        
        groupName: Yup.string().required(announcementLabel.group_required),
    });


    
    useEffect(() => {
        dispatch(announcement_group_get()).then(response => {
            if (response?.payload?.status === 200) {
                setGroupdetails(response?.payload?.data?.announcement_groups || []);
            }
        });
    }, []);
   



    const fileInputRef = useRef(null);

    const handlesubmit = async (values, formik) => {
        let response = await dispatch(announcement_user_create(values));
        const isSuccess = response?.payload?.status === 200;
        const messageType = isSuccess ? "success" : "error";

        toast(response?.payload?.data?.message, {
            type: messageType,
            autoClose: 2000,
        });

        if (isSuccess) {
            dispatch(announcement_user_details())

            const fieldsToReset = ['name', 'contactNumber', 'groupName'];

            fieldsToReset.forEach((field) => {
                formik.setFieldValue(field, '');
                formik.setFieldTouched(field, false);
            });


            formik.resetForm();

        } else {

            toast(response?.payload?.data?.error, {
                type: "error",
                autoClose: 2000,
            });
        }
    };

    

    const handledataSubmit = (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('file', values.csvFile);
        formData.append('groupName', values.groupName);
        dispatch(announcement_bulkadd(formData))
        resetForm()
    }
    useEffect(() => {
        console.log(csv_data)
        if (csv_data?.status == 200) {
            toast(csv_data?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            fileInputRef.current.value = '';
        } else {
            toast(csv_data?.data?.error, {
                type: "error",
                autoClose: 2000
            });
        }
        dispatch(clearOldState());
    }, [csv_data]);

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ name: '', contactNumber: '', groupName: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handlesubmit}
                            >
                                <Form>
                                    <div className='row  px-4 mt-0 mt-sm-2'>
                                        <div className='col-sm-6'>
                                            <label className='form-label'>User Name</label>
                                            <Field type='text' className='form-control' placeholder='Enter user Name' name='name' />
                                            <ErrorMessage name='name' component='div' className="text-danger" />
                                        </div>
                                        <div className='col-sm-6'>
                                            <label className='form-label'>Phone Name</label>
                                            <Field type='number' className='form-control' placeholder='Enter Phone Name' name='contactNumber' />
                                            <ErrorMessage name='contactNumber' component='div' className="text-danger" />
                                        </div>
                                    </div>

                                    <div className='row  px-4 mt-0 mt-sm-2'>
                                        <div className='col-sm-6'>
                                            <label className='form-label'>Select Group</label>
                                            <Field as='select' className='form-select' name='groupName'>
                                                <option value=''>Select Group Name</option>
                                                {groupdetails.length !== 0 ? (
                                                    groupdetails.map((data, index) => (
                                                        <option key={index} value={data.group}>
                                                            {data.group}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">No groups available</option>
                                                )}

                                            </Field>
                                            <ErrorMessage name='groupName' component='div' className="text-danger" />
                                        </div>

                                    </div>
                                    <div className=' m-4'>
                                        <button className="btn defult-button" type="submit">Submit</button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                        
                        <div className='card custom-card mt-3'>
                            <>
                                <Formik
                                    initialValues={{ csvFile: null,groupName: '' }}
                                    onSubmit={handledataSubmit}
                                    validationSchema={validationSchema1}

                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handledataSubmit,
                                        handleChange,
                                        setFieldValue,
                                        resetForm
                                    }) => (
                                        <Form>
                                            <div className='row m-2'>
                                            <div className='col-md-6 '>
                                                <label className='form-label'>Upload the Excel </label>
                                                <input ref={fileInputRef}
                                                    type='file'
                                                    accept='.xlsx, .xls'
                                                    className='form-control'
                                                    placeholder='Enter your name'
                                                    name='csvFile'
                                                    onChange={(event) => {
                                                        setFieldValue('csvFile', event.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage name='csvFile' component='div' className='text-danger' />


                                            </div>
                                            <div className='col-md-6'>
                                                <label className='form-label'>Select Group</label>
                                                <Field as='select' className='form-select' name='groupName'>
                                                    <option value=''>Select Group Name</option>
                                                    {groupdetails.length !== 0 ? (
                                                        groupdetails.map((data, index) => (
                                                            <option key={index} value={data.group}>
                                                                {data.group}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">No groups available</option>
                                                    )}

                                                </Field>
                                                <ErrorMessage name='groupName' component='div' className="text-danger" />
                                            </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='mx-3 mt-2'>
                                                        <button className='btn defult-button ms-2' type='submit' disabled={!values.csvFile || loading}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                        </Form>
                                    )}
                                </Formik>
                                <div className='mt-2 mx-4'>
                                    <p><span className='text-danger'>Note *</span> : Name,Contact Number is required</p>
                                </div>
                                {loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : ''}
                            </>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    )
}
