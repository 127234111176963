import { createAsyncThunk } from "@reduxjs/toolkit";
import { endPoint } from "../config/endPoint";
import { apiDelete, apiGet, apiPost } from "../service/apiService";


export const category_create = createAsyncThunk("category_create",async (payload) => {
    const response = await apiPost(endPoint.category , payload, "");
    return response
})

export const category_get = createAsyncThunk("category_get", async (payload) => {
    const response = await apiGet(endPoint.category, payload,"");
    return response
})




export const category_delete = createAsyncThunk("staffdelete", async (payload) => {
    console.log(payload)
    const response = await apiDelete(endPoint.category + '/' + payload)
    return response
})
