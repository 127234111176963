import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'
import { israelDelete, israelget } from '../../../middleware/IsrealMiddleware';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../component/Pagination';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
export default function Israel() {
    const israelData = useSelector(state => state.Israel);
    let role = Cookies.get("role");
    const [israelModifideData, setIsraelModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,
        israelDataList: null,
        currentPage: 1,
        totalCount: 0,
        pageSize: 100,
        search: ''

    });


    const [search, setSearch] = useState()
    const dispatch = useDispatch()



    useEffect(() => {
        if (israelData?.israel?.status === 200) {

            setIsraelModifideData((prs) => {
                return {
                    ...prs,
                    israelDataList: israelData?.israel?.data,
                    deleteModel: false,
                    deleteIdData: null,
                    totalCount: israelData?.israel?.data?.totalCandidates,

                }
            })

        }

        if (israelData?.deleteData?.status === 200) {
            toast(israelData?.deleteData?.data?.message, {
                type: "success",
                autoClose:2000
            });
             
            dispatch(israelget({ pageNo: 1, count: 100 }));
        } else {
            toast(israelData?.deleteData?.response?.data?.message, {
                type: "error",
                autoClose:2000
            });
        }



    }, [israelData])


    useEffect(() => {
        if (israelModifideData.currentPage) {
            getoveralldata();
        }
    }, [israelModifideData.currentPage, search])


    const getoveralldata = () => {
        const obj = {};
        obj['pageNo'] = israelModifideData.currentPage;
        obj['count'] = israelModifideData.pageSize;
        if (search) {
            obj['searchText'] = search;
        }
        dispatch(israelget(obj))
    }
    const setCurrentPage = (page) => {
        setIsraelModifideData((prs) => {
            return {
                ...prs,
                currentPage: page,
            }
        })

    }


    const deleteStaffData = () => {
        dispatch(israelDelete(israelModifideData?.deleteIdData?.id))
        // https://backend.astrocdms.in/api/v1/israel_registration?pageNo=1&count=100
    }

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row'>
                                <div className='col-md-9'></div>
                                <div className='col-md-3 d-flex justify-content-end align-items-center p-2'>
                                    <input className='mx-2 form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table table-striped-custom table-hover">
                                    <thead className='table-head'>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Father's Name</th>
                                            {/* <th className='text-nowrap'>Passport No</th> */}
                                            <th>Profession</th>
                                            <th>Contact</th>
                                            <th>Date</th>
                                            {/* <th>Referral</th> */}
                                            {/* <th className='text-nowrap'>Emergency Contact</th> */}
                                            <th className='text-nowrap'>FIle download</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {israelModifideData?.israelDataList?.candidates.length !== 0 ?
                                            (israelModifideData?.israelDataList?.candidates && israelModifideData?.israelDataList?.candidates.map((data, index) => (
                                                <tr key={index}>
                                                    <td className='text-center p-0 m-0'>{index + 1 + (israelModifideData.currentPage - 1) * israelModifideData.pageSize}</td>
                                                    <td className='p-0 m-0'>{data.name}</td>
                                                    <td className='p-0 m-0'>{data.father_name}</td>
                                                    {/* <td>{data.passport_number}</td> */}
                                                    <td className='p-0 m-0'>{data.profession}</td>
                                                    <td className='p-0 m-0'>{data.contact}</td>
                                                    <td className='p-0 m-0'>{data.created_at !== null ? formatDate(data.created_at) : ''}</td>  
                                                    {/* <td>{data.referral}</td> */}
                                                    {/* <td>{data.emergency_contact}</td> */}
                                                    <td className='p-0 m-0'><a href={data.file_download} target="_blank">Download File</a></td>
                                                    <td className='p-0 m-0'>
                                                        <div className='d-flex'>
                                                            <Button className='table-viewcustom-button my-1'><Link to={`/israel/edit/${data.id}`}><i className="fa fa-eye px-3" aria-hidden="true"></i></Link></Button>
                                                            {role !== 'israel' ? <Button className='table-custom-button my-1' variant="primary" onClick={() => setIsraelModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: data,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>:''}

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))) :
                                            <tr>
                                                <td colSpan="10" className='text-center'>No data available</td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                { israelModifideData?.totalCount > 0 ?
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={israelModifideData.currentPage}
                                        totalCount={israelModifideData.totalCount}
                                        pageSize={israelModifideData.pageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal show={israelModifideData.deleteModel} onClick={() => setIsraelModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this staff <b>{israelModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsraelModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" onClick={() => deleteStaffData()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}