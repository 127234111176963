import { createSlice } from "@reduxjs/toolkit";
import { category_create, category_delete, category_get } from "../middleware/AttendanceMiddleware";

const AttendanceSlice = createSlice({
    name: 'Attendance',
    initialState: {
        loading:false,
        category:null,
        error:null,
        deleteData:null
    },
   
    extraReducers: (builder) => {
        builder
           
            .addCase(category_create.pending, (state) => {
                state.loading = true;
            })
            .addCase(category_create.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.category = action.payload
                
            })
            .addCase(category_create.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.category = [];
            })


            .addCase(category_get.pending, (state) => {
                state.loading = true;
            })
            .addCase(category_get.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.category = action.payload
                state.deleteData=null
            })
            .addCase(category_get.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.category = [];
            })


            .addCase(category_delete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(category_delete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(category_delete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })


        }
})



export default AttendanceSlice.reducer
