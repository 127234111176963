import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const israelget = createAsyncThunk("israelget",async (payload) => {
    const response = await apiGet(endPoint.Israel , payload, "");
    return response
}
)


export const israelgetbyid = createAsyncThunk("israelgetbyid", async (payload) => {
    const response = await apiGet(endPoint.Israel + "/" + payload)
    return response
})


export const israelDelete = createAsyncThunk("israeldelete", async (payload) => {
    const response = await apiDelete(endPoint.Israel + '/' + payload)
    return response
})