import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'

import { useNavigate } from 'react-router-dom';
import './Candidatelist.css'
import AllList from './All_list'
import PendingList from './Pending_list'
import CompletedList from './Completed_list'
import Header from '../../Navbar/Header';
import { useDispatch, useSelector } from 'react-redux';
import { clearOldState } from "../../../store/CandidateSlice"
import { candidatdelete, candidateget } from '../../../middleware/CandidateMiddleware';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '../../../service/apiService';
import { endPoint } from '../../../config/endPoint';
import { admincount, overallcount } from '../../../middleware/CountMiddleware';
import Cookies from 'js-cookie';

import DatePicker from "react-datepicker";
export default function Candidatelist() {
  const navicate = useNavigate()
  const dispatch = useDispatch()

  const [search, setSearch] = useState()
  const [searchnumber, setSearchnumber] = useState(1)
  const [filterdate, setFilterdate] = useState(null)



  const addcandidate = () => {
    dispatch(clearOldState());
    navicate('new')
  }

  const pendingargs = { status: 'pending' }
  const completedargs = { status: 'completed' }


  const candidatedata = useSelector(state => state.Candidate);
  const count = useSelector(state => state.Count)


  const [getoverallcount, setGetoverallcount] = useState('')
  const [getpendingcount, setPendingcount] = useState('')
  const [getcompletedcount, setCompletedcount] = useState('')




  useEffect(() => {
    dispatch(admincount())
  }, [])

  useEffect(() => {
    if (count?.count?.status === 200) {
      setGetoverallcount(count?.count?.data?.data?.all_data)
      setPendingcount(count?.count?.data?.data?.pending_data)
      setCompletedcount(count?.count?.data?.data?.completed_data)
    }
  }, [count])


  const alldata = () => {
    setFilterdate(null)
    setSearchnumber(1)
    dispatch(candidateget())
    dispatch(admincount())
  }


  // all data delete
  const alldatadelete = async (candidateId) => {
    try {
      const response = await dispatch(candidatdelete(candidateId));
      dispatch(candidateget());
      dispatch(admincount())

      toast(response.payload.data.message, {
        type: "success"
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while deleting the candidate.");
      }
    }
  };

  //pending data delete
  const pendingadelete = async (candidateId) => {
    try {
      const response = await dispatch(candidatdelete(candidateId));
      dispatch(candidateget(pendingargs))
      dispatch(overallcount())

      toast(response.payload.data.message, {
        type: "success"
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while deleting the candidate.");
      }
    }
  };

  // completed data delete
  const completeddelete = async (candidateId) => {
    try {
      const response = await dispatch(candidatdelete(candidateId));
      dispatch(candidateget(completedargs))
      dispatch(overallcount())

      toast(response.payload.data.message, {
        type: "success"
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while deleting the candidate.");
      }
    }
  };


  const pendingdata = () => {
    setFilterdate(null)

    setSearchnumber(2)
    dispatch(candidateget(pendingargs))


  }
  const completeddata = () => {
    setFilterdate(null)

    setSearchnumber(3)
    dispatch(candidateget(completedargs))
  }



  const handleDownload = async () => {
    console.log('entry')
    let param = {};
    if (searchnumber === 2) {
      param = {
        status: 'pending'
      }
    } else if (searchnumber === 3) {
      param = {
        status: 'completed'
      }
    } else {
      param = {
        status: ''
      }
    }
    param.testMonth = formatDate(filterdate)
    try {

      const response = await axios.get(baseUrl + endPoint.download_candidate,
        {
          params: param,
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'candidates.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading:', error);
    }
  }
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  function formatDate(date) {
    if (date){
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date).toLowerCase();    
      const year = date.getFullYear();
      return `${month}-${year}`;
    }
    
}
  const formattedfilterdate = filterdate ? formatDate(filterdate) : '';

  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <Header />
          <div className='col-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-10 mt-3 px-3'>
            <div className='card custom-card '>
              <div className='row mb-3 mt-3'>
                <div className='col-md-5 px-4'>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link custom-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => alldata()}>All Items ({getoverallcount})</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link custom-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => pendingdata()}>Pending Items ({getpendingcount})</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link custom-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => completeddata()
                      }>Completed Items ({getcompletedcount})</button>
                    </li>
                  </ul>
                </div>
                <div className='col-md-7 d-block d-md-flex justify-content-end align-items-center px-2 canditate-list'>
                  <div className='d-flex'>
                  <DatePicker className='form-control datefilter' placeholderText='Select filter'
                    renderMonthContent={renderMonthContent}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    selected={filterdate}                   
                    onChange={(date) => {
                      setFilterdate(date);
                    }}
                  />
                  <button className="table-viewcustom-button p-1 btn btn-primary" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                  </div>
                  <button className='download m-2' onClick={handleDownload}>Download Excel<i className="fa fa-download ms-2" aria-hidden="true"></i></button>
                  <input className='m-2 form-control search-btn' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                  <button className='btn defult-button defult-button-head m-2 text-nowrap' type="button" onClick={() => addcandidate()}>Add New</button>
                </div>
              </div>
              <div className='row'>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    {searchnumber === 1 ?
                      <AllList data={candidatedata.candidatedata} filter={formattedfilterdate} search={searchnumber === 1 && search ? search : ''} deletedata={(candidateId) => alldatadelete(candidateId)} /> : ''}
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    {searchnumber === 2 ?
                      <PendingList data={candidatedata.candidatedata} filter={formattedfilterdate} search={searchnumber === 2 && search ? search : ''} deletedata={(candidateId) => pendingadelete(candidateId)} />
                      : ''}
                  </div>

                  <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    {searchnumber === 3 ?
                      <CompletedList data={candidatedata.candidatedata} filter={formattedfilterdate} search={searchnumber === 3 && search ? search : ''} deletedata={(candidateId) => completeddelete(candidateId)} /> : ''}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
