import { createSlice } from "@reduxjs/toolkit";
import { resourceDelete, resourceget, resourcegetbyid } from "../middleware/ResourceMiddleware";

const ResourceSlice = createSlice({
    name: 'Resource',
    initialState: {
        loading: false,
        resource: null,
        error: null,
        
        deleteData: null,
        getresource : null,
        updateresource:null
    },
    extraReducers: (builder) => {
        builder
           
            .addCase(resourceget.pending, (state) => {
                state.loading = true;
                state.deleteData = null
            })
            .addCase(resourceget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.resource = action.payload
                
            })
            .addCase(resourceget.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
                state.resource = null;
            })



            .addCase(resourcegetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(resourcegetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getresource = action.payload
                state.error = null
                state.updateresource=null
            })
            .addCase(resourcegetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })

            .addCase(resourceDelete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(resourceDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(resourceDelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })

    }
})



export default ResourceSlice.reducer
