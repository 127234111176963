import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../service/apiService";
import { endPoint } from "../config/endPoint";


export const staffreportget = createAsyncThunk("staffreportget", async (payload) => {
    const response = await apiGet(endPoint.staff_report, payload, "");
    return response
})


export const tradereportget = createAsyncThunk("tradereportget", async (payload) => {
    const response = await apiGet(endPoint.trade_report, payload, "");
    return response
})