// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-bg{
    position: fixed;
    overflow: hidden;
    height: 100%; 
    background-color: #2F3C4E;
    width: 16.5%;
    top: 75px;
}
.sidebar-bg ul li p {
  cursor: pointer;
}
.sidebar-bg ul li a, .sidebar-bg ul li p{
    color: #fff;
    text-decoration: none;
    line-height: 2.70rem;
}
.submenu li a {
 line-height: 2.20rem !important;
}
.sidebar-bg ul li i {
    color:#fff
}
.sidebar-bg ul{
    list-style: none;     
    padding: 0;
}

.sidebar-bg ul li.active, .sidebar-bg ul li p.active {
    background-color: #B32523;
  }

.sidebar.open li span {
    display: none;
} 
.submenu {
  background: #2F3C4E;
}

@media (max-width: 1200px) {
  .sidebar-bg {
    width: 18%
  }
}
@media (max-width: 768px) {
  .sidebar-bg li span{
    display: none;
  }
  .sidebar-bg {
    width: 10%
  }
}
  @media (max-width: 480px) {
    .sidebar-bg {
      width: 15%
    }
    .sidebar-bg li span{
      display: none;
    }
    .sidebar-bg ul li i {
        font-size: 20px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/layouts/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,SAAS;AACb;AACA;EACE,eAAe;AACjB;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,oBAAoB;AACxB;AACA;CACC,+BAA+B;AAChC;AACA;IACI;AACJ;AACA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,yBAAyB;EAC3B;;AAEF;IACI,aAAa;AACjB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE;EACF;AACF;AACA;EACE;IACE,aAAa;EACf;EACA;IACE;EACF;AACF;EACE;IACE;MACE;IACF;IACA;MACE,aAAa;IACf;IACA;QACI,eAAe;IACnB;EACF","sourcesContent":[".sidebar-bg{\n    position: fixed;\n    overflow: hidden;\n    height: 100%; \n    background-color: #2F3C4E;\n    width: 16.5%;\n    top: 75px;\n}\n.sidebar-bg ul li p {\n  cursor: pointer;\n}\n.sidebar-bg ul li a, .sidebar-bg ul li p{\n    color: #fff;\n    text-decoration: none;\n    line-height: 2.70rem;\n}\n.submenu li a {\n line-height: 2.20rem !important;\n}\n.sidebar-bg ul li i {\n    color:#fff\n}\n.sidebar-bg ul{\n    list-style: none;     \n    padding: 0;\n}\n\n.sidebar-bg ul li.active, .sidebar-bg ul li p.active {\n    background-color: #B32523;\n  }\n\n.sidebar.open li span {\n    display: none;\n} \n.submenu {\n  background: #2F3C4E;\n}\n\n@media (max-width: 1200px) {\n  .sidebar-bg {\n    width: 18%\n  }\n}\n@media (max-width: 768px) {\n  .sidebar-bg li span{\n    display: none;\n  }\n  .sidebar-bg {\n    width: 10%\n  }\n}\n  @media (max-width: 480px) {\n    .sidebar-bg {\n      width: 15%\n    }\n    .sidebar-bg li span{\n      display: none;\n    }\n    .sidebar-bg ul li i {\n        font-size: 20px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
