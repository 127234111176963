import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiDelete, apiGet, apiPost, apiPut } from '../service/apiService';

export const admissionadd = createAsyncThunk("admissionadd", async (payload) => {
    const response = await apiPost(endPoint.admission, payload);
    return response;
});

export const admissionget = createAsyncThunk("admissionget", async (payload) => {
    const response = await apiGet(endPoint.admission, payload)
    return response
})

export const admissionDelete = createAsyncThunk("admissionDelete", async (payload) => {
    const response = await apiDelete(endPoint.admission + '/' + payload)
    return response
})


export const admissionUpdate = createAsyncThunk("admissionUpdate",async (payload)=>{
    const response = await apiPut(endPoint.admission + "/"+payload?.id ,payload.data);
    return response;
})


export const admissiongetbyid = createAsyncThunk("admissiongetbyid", async (payload) => {
    const response = await apiGet(endPoint.admission + "/" + payload)
    return response
})
