import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'

import { Field, Form, Formik } from 'formik'

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { israelgetbyid } from '../../../middleware/IsrealMiddleware';
import DatePicker from "react-datepicker";

import { format } from 'date-fns';
export default function IsraelEdit() {
    const dispatch = useDispatch()

    const israelData = useSelector(state => state.Israel.getisrael);

    const navigate = useNavigate()
    const [initialState, setInitialState] = useState({
        name: "", father_name: '', dob: '',
        email: '', contact: '', emergency_contact: '',
        passport_number: '', passport_expiry_date: '',

    })

    const cancelsubmit = () => {
        navigate(-1);
    }
    let { id } = useParams();

    useEffect(() => {
        dispatch(israelgetbyid(id))
    }, [])

    useEffect(() => {
        if (israelData?.status === 200) {
            setInitialState(israelData?.data);
        }
    }, [israelData])
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    enableReinitialize
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Name</label>
                                                    <Field placeholder='User Name' className='form-control' name="name" value={values?.name} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Father Name</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="father_name" value={values?.father_name} />
                                                </div>
                                            </div>


                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field placeholder='User Name' className='form-control' name="contact" value={values?.contact} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="email" value={values?.email} />
                                                </div>
                                            </div>




                                            {/* <div className='row px-4 mt-0 mt-sm-2'>
                                            <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>DOB</label>
                                                    <DatePicker placeholder='User Name' className='form-control' name="dob" 
                                                    value={values?.dob ? format(new Date(values.dob), 'dd/MM/yyyy') : ''}
                                                    />
                                                </div>
                                               
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Emergency Contact Number</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="emergency_contact" value={values?.emergency_contact} />
                                                </div>
                                            </div> */}



                                            {/* <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport number</label>
                                                    <Field placeholder='User Name' className='form-control' name="passport_number" value={values?.passport_number} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Expiry Date</label>
                                                    <DatePicker placeholder='User Name' className='form-control' name="passport_expiry_date" 
                                                    value={values?.passport_expiry_date ? format(new Date(values.passport_expiry_date), 'dd/MM/yyyy') : ''}
                                                    />
                                                </div>
                                            </div> */}



                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Photo</label>
                                                    <input placeholder='User Name' type="file" className='form-control' />
                                                    <a href={values.passport_photo} target="_blank" rel="noreferrer">
                                                        Download passport
                                                    </a>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Government Proof</label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.government_id} target="_blank" rel="government_id">
                                                        Download government proof
                                                    </a>
                                                </div>
                                            </div>



                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Education</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="profession" value={values?.profession} />

                                                </div>
                                                {values.partner_passport ? 
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Download Partner passport</label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.partner_passport} target="_blank" rel="resume">
                                                        Download Partner passport
                                                    </a>
                                                </div>:''}
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                            {values.partner_government_id ? 
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Download Partner Government id</label>
                                                    <input placeholder='Enter the name' type="file" className='form-control' />
                                                    <a href={values.partner_government_id} target="_blank" rel="resume">
                                                        Download Partner Government id
                                                    </a>
                                                </div>:''}
                                                {values.partner_dob ? 
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                   
                                                    <label className='form-label'>Date of Birth</label>
                                                    <DatePicker placeholderText='Select the DOB' className='form-control' name="partner_dob"
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.partner_dob ? format(new Date(values.partner_dob), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('partner_dob', date) }}

                                                    />
                                                </div>:''}
                                            </div>




                                            <div className='row px-4 mt-0 mt-sm-2'>
                                            
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Marital status</label>
                                                    <Field placeholder='Enter the name' className='form-control'  value={values?.martial_status} />
                                                </div>
                                                
                                            </div>


                                            

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>

                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}