
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
// import { addResume, udpateResume } from './ResumeSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { addEmployee, getCompany, salaryCalculator, udpateEmployee } from '../hrmsSlice';



export default function EmployeeData({ handleBack, editData, companydetails }) {
    const dispatch = useDispatch();
    console.log(companydetails)
    const [initialValues, setInitialValues] = useState({
        employee_code: '',
        name: '',
        email: '',
        phone_number: '',
        company_id: '',
        total_salary: '',
        basic_pay: '',
        hra: '',
        other_allowances: ''


    });

    const validationSchema = Yup.object().shape({
        employee_code: Yup.string().required("Employee Code is required"),
        name: Yup.string().required("User Name is required"),
        email: Yup.string().required("Email is required"),
        phone_number: Yup.string().required("Phone Number is required"),
        total_salary: Yup.string().required("Current Salary is required"),
        basic_pay: Yup.string().required("basic pay  is required"),
        hra: Yup.string().required("Hra Salary is required"),
        other_allowances: Yup.string().required("other allowances is required"),

    });
    useEffect(() => {
        if (companydetails) {
            setInitialValues((prevValues) => ({
                ...prevValues,
                company_id: companydetails.company_id // Assuming `id` is available in `companyDetails`
            }));
        }
    }, [companydetails]);
    const handleSubmit = async (values, { resetForm }) => {

        if (editData && editData.employee_id) {
            const { employee_id, salary_process, payslip_file_path, ...editDataWithoutId } = editData;
            const { id, total_salary, basic_pay, other_allowances, hra, ...valuesWithoutId } = values;
            console.log(total_salary)

            const updatedEditData = {
                ...editDataWithoutId,
                ...valuesWithoutId,
                total_salary: total_salary && parseFloat(total_salary.toString().replace(/,/g, '')), // Convert to string if necessary, handle null
                other_allowances: other_allowances && typeof other_allowances === 'string' ? parseFloat(other_allowances.replace(/,/g, '')) : other_allowances,
                hra: hra && typeof hra === 'string' ? parseFloat(hra.replace(/,/g, '')) : hra,
                basic_pay: basic_pay && typeof basic_pay === 'string' ? parseFloat(basic_pay.replace(/,/g, '')) : basic_pay,
                company_id: companydetails.company_id,
            };
            console.log('before')
            const response = await dispatch(udpateEmployee({ data: updatedEditData, employee_id: editData.employee_id })).unwrap();
            console.log('entry')
            if (response.status === 200) {
                handleBack();
                toast.success('Candidate Resume Updated Successfully');
            } else {
                toast.error()
            }
        } else {
            const response = await dispatch(addEmployee(values)).unwrap();
            console.log(response)
            if (response.status === 201) {
                resetForm()
                handleBack()
                toast.success('Company Added Successfully');
            } else {
                toast.error(response?.data?.message)
            }
        }

    };


    useEffect(() => {

        if (editData) {
            const formattedSalary =
                typeof editData?.total_salary === 'string'
                    ? editData.total_salary.replace(/,/g, '')
                    : editData?.total_salary?.toString().replace(/,/g, '') || '';

            setInitialValues({
                employee_code: editData.employee_code || '',
                id: editData.id || '',
                name: editData.name || '',
                email: editData.email || '',
                phone_number: editData.phone_number || '',
                total_salary: formattedSalary || '',
                hra: editData.hra || '',
                basic_pay: editData.basic_pay || '',
                other_allowances: editData.other_allowances || '',

            });
        }
    }, [editData]);
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getCompany());
        };
        fetchData();
    }, [dispatch]);

    const handleTotalSalaryBlur = async (event, setFieldValue) => {
        const value = event.target.value;
        if (value !== '') {
            const response = await dispatch(salaryCalculator(Number(value)));
            console.log(response)
            if (response.payload.status === 200) {
                setFieldValue('basic_pay', response.payload.data.basic_pay);
                setFieldValue('hra', response.payload.data.hra);
                setFieldValue('other_allowances', response.payload.data.other_allowances);
            }
        }
    };
    return (
        <>

            <div className="col-md-12">
                <div className=" p-3">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, setFieldValue }) => {


                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Employee Code</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter User Name'
                                                name='employee_code'
                                            />
                                            <ErrorMessage name='employee_code' component='div' className="text-danger" />
                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Name</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter User Name'
                                                name='name'
                                            />
                                            <ErrorMessage name='name' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Email</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter User Email'
                                                name='email'
                                            />
                                            <ErrorMessage name='email' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Phone Number</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Phone Number'
                                                name='phone_number'
                                            />
                                            <ErrorMessage name='phone_number' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Company</label>
                                            <Field as="select" className='form-control' name="company_id" disabled>
                                                <option value={companydetails.company_id} label={companydetails?.company_name || 'Loading...'} />
                                            </Field>
                                            <ErrorMessage name='company_id' component='div' className="text-danger" />

                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Total Salary</label>
                                            <Field
                                                type='number'
                                                className='form-control'
                                                placeholder='Enter Total Salary'
                                                name='total_salary'
                                                onBlur={(event) => handleTotalSalaryBlur(event, setFieldValue)} // Update here
                                            />
                                            <ErrorMessage name='total_salary' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Basic Salary</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Basic Salary'
                                                name='basic_pay'
                                                disabled
                                            />
                                            <ErrorMessage name='basic_pay' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>HRI</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter HRI'
                                                name='hra'
                                                disabled
                                            />
                                            <ErrorMessage name='hra' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Other Allowance</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Other Allowance'
                                                name='other_allowances'
                                                disabled
                                            />
                                            <ErrorMessage name='other_allowances' component='div' className="text-danger" />

                                        </div>




                                        <div className="row mt-3">
                                            <div className="col ">

                                                <button className='cancel-button ms-2' onClick={handleBack}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}
