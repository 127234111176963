import React from 'react'
import { useEffect } from 'react';
import { tradereportget } from '../../../../middleware/AttendancereportMiddleware';
import { useDispatch } from 'react-redux';

export default function Tradereport(props) {
    
  const dispatch = useDispatch()
    const reporttrade = props.data
    const filterdate = props.filter
    const searchdata = props.search
    const filtercategory = props.filtercategory
    
    const filtertodate = props.todate
    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
      }
      useEffect(() => {
        getoveralldata();
    }, [filterdate,searchdata,filtertodate,filtercategory])


    const getoveralldata = () => {
        const obj = {};
        if (searchdata) {
            obj['searchText'] = searchdata;
        }
        if (filterdate) {
            obj['from_date'] = filterdate
        }
        if (filtertodate){
            obj['to_date'] = filtertodate
        }
        if (filtercategory){
            obj['trade_id'] = filtercategory
        }
        dispatch(tradereportget(obj))
    }

    return (
        <div>
            <div className='table-responsive'>
                <table className="table table-striped-custom table-hover">
                    <thead className='table-head'>
                        <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Staff Name</th>
                            <th className='text-nowrap'>Morning Attendance</th>
                            <th className='text-nowrap'>Morning Attendance By</th>
                            <th className='text-nowrap'>Evening Attendance</th>
                            <th className='text-nowrap'>Evening Attendance By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reporttrade && reporttrade.length > 0 ? (
                            reporttrade.map((data, index) => (
                                <tr key={data.id}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(data.date)}</td>
                                    <td >{data.tradeName}</td>                                    
                                    <td className='text-center'>{data.morningCheckin || '--'}</td>
                                    <td>{data.morningCreatedBy}</td>                                   
                                    <td className='text-center'>{data.eveningCheckout || '--'}</td>
                                    <td>{data.eveningCreatedBy}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className='text-center'>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
