import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { staffUpdate, staffgetbyid } from '../../../middleware/StaffMiddleware';

import { Field, Form, Formik } from 'formik'

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Header from '../../Navbar/Header';
import { candidateErrorLabel } from '../../../config/labels/ErrorLabel';
export default function StaffEditForm() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const staffData = useSelector(state => state.Staffdata.getstaff);
  const staffDataupdate = useSelector(state => state.Staffdata.updatestaff);

  const [password, setPassword] = useState('')

  const [previewImage, setPreviewImage] = useState('')



  useEffect(() => {
    if (staffDataupdate?.status === 200) {
      toast(staffDataupdate?.data?.message, {
        type: "success"
      });
     
      navigate(-1);
    } else {
      if (staffDataupdate?.response?.status === 400) {
        toast(staffDataupdate?.response?.data?.message, {
          type: "error"
        });
      }
    }
  }, [staffDataupdate])

  let { id } = useParams();


  useEffect(() => {
    dispatch(staffgetbyid(id))
  }, [])

  useEffect(() => {
    if (staffData?.status === 200) {
      setInitialState(staffData?.data);

      const standardizedBase64 = staffData?.data?.password.replace(/-/g, "+").replace(/_/g, "/");
      setPassword(atob(standardizedBase64));

    }
  }, [staffData])

  const cancelsubmit = () => {
    navigate(-1);    
  }
  const [initialState, setInitialState] = useState({
    contactNumber: "",
    id: "",
    lastLoginTime: null,
    name: "",
    profileImage: null
  })
  const staffSchema = Yup.object().shape({

    name: Yup.string().required(candidateErrorLabel.name_required),
  })

  const Updatestaff = (values) => {


    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('contactNumber', values.contactNumber);
    if (values.profileImage) {
      formData.append('profileImage', values.profileImage); // Append the image file
    }
    const param = { id: id, data: formData }
    dispatch(staffUpdate(param));
  }



  

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>

          <Header />
          <div className='col-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-10 mt-2 px-3'>

            <div className='card custom-card'>
              <div className='row mb-3 mt-2'>
                <Formik
                  initialValues={initialState}
                  validationSchema={staffSchema}
                  enableReinitialize
                  onSubmit={Updatestaff}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>User Id</label>
                          <Field placeholder='User Id' className='form-control' name="name" value={values?.id} disabled />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Name</label>
                          <Field placeholder='Enter the Name' className='form-control' name="name" value={values?.name} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Password</label>
                          <Field className='form-control' value={password} disabled />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Contact Number</label>
                          <Field type='number' placeholder='Enter the contact Number' className='form-control' name="contactNumber" value={values?.contactNumber} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Role</label>
                          <Field placeholder='Select the role' className='form-control' name="role" value={values?.role === 'staff' ? 'Enrolment' :'manager'} disabled />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>

                        </div>
                      </div>

                      <div className='row px-4 mt-0 mt-sm-2'>

                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Image</label>
                          <input type="file" className='form-control'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setFieldValue('profileImage', file);

                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPreviewImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              } else {
                                setPreviewImage(null);
                              }
                            }}
                          />
                          <div className='col profileimage'>
                            {previewImage ? (
                              <img src={previewImage} alt="Profile Preview" />
                            ) : values?.profileImage ? (
                              <img src={values.profileImage} alt="Profile" />
                            ) : null}
                          </div>
                        </div>
                        <div className='col'>

                        </div>
                      </div>



                      <div className='px-4 mt-3'>
                        <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>
                        <button className="btn defult-button ms-2" type='submit'>Submit</button>

                      </div>
                    </Form>)}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <NotificationModal show={showModal} onClose={handleCloseModal} /> */}
    </>
  )
}


