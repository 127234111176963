import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'
import '../sidebar/Sidebar.css'


import Modal from 'react-bootstrap/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { attendanceLabel } from '../../../config/labels/ErrorLabel'
import { toast } from 'react-toastify';
import { category_create, category_delete, category_get } from '../../../middleware/AttendanceMiddleware';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';

export default function Category() {

    const dispatch = useDispatch()
    const categorydata = useSelector(state => state.Category)
    const [category, setCategory] = useState();

    const [categoryModifideData, setCategoryModifideData] = useState({
        deleteModel: false,
        deleteIdData: null,


    });

    const validationSchema = Yup.object().shape({
        category: Yup.string().required(attendanceLabel.category_required),
    })

    const handlesubmit = async (values, formik) => {
        let response = await dispatch(category_create(values))
        console.log(response)
        if (response?.payload?.status === 200) {
            toast(response?.payload?.data?.message, {
                type: "success",
                autoClose: 2000
            });
            dispatch(category_get())

            formik.setFieldValue('category', '');
            formik.setFieldTouched('category', false)
        } else if (response?.payload?.status !== 200) {
            toast(response?.payload?.data?.message, {
                type: "error",
                autoClose: 2000
            });
        }
    }

    useEffect(() => {
        dispatch(category_get())
    }, [])




    useEffect(() => {
        if (categorydata?.category?.status == 200) {
            setCategory(categorydata?.category?.data || []);
        }
    }, [categorydata])

   
    const deleteStaffData = async(categoryId) => {
        const response = await dispatch(category_delete(categoryId));
        console.log(response)
        if (response?.payload?.status === 200) {
          setCategory((prevCategory) =>
            prevCategory.filter((category) => category.id !== categoryId)
          );
      
          toast(response?.payload?.data?.message, {
            type: "success",
            autoClose: 2000
          });
        } else {
          toast(response?.payload?.response?.data?.message, {
            type: "error",
            autoClose: 2000
          });
        }
      };
      

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <Formik
                                initialValues={{ category: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handlesubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className='row px-4 mt-2'>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>Category Name</label>
                                                <Field type='text' className='form-control' placeholder='Enter Candidate Name' name='category' />
                                                <ErrorMessage name='category' component='div' className="text-danger" />
                                            </div>

                                        </div>


                                        <div className=' m-4'>
                                            <button className="btn defult-button" type="submit">Submit</button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className='card custom-card mt-3'>
                            <div className='table-responsive border border-1'>
                                <table className='table table-striped-custom table-hover'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='text-center'>S.No</th>
                                            <th className='text-center'>Category</th>
                                            <th className='text-center'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {category && category.length > 0 ? (
                                            category.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td className='text-center'> {data.name}</td>
                                                    <td>
                                                        <div className='text-center'>

                                                            <Button className='table-custom-button  my-1' variant="primary" onClick={() => setCategoryModifideData((prs) => {
                                                                return {
                                                                    ...prs,
                                                                    deleteIdData: data,
                                                                    deleteModel: true
                                                                };
                                                            })
                                                            }
                                                            ><i className='fa fa-trash px-3'></i></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className='text-center'>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={categoryModifideData.deleteModel} onClick={() => setCategoryModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this staff <b>{categoryModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCategoryModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" 
                      onClick={() => deleteStaffData(categoryModifideData?.deleteIdData?.id)}

                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
